import { Component, OnDestroy, OnInit } from '@angular/core';
import { Conversation } from 'src/app/shared/models/conversation.model';
import { MessageService } from 'src/app/shared/services/message.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sidebar-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class SidebarMessageComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    message: Conversation|null = null;

    constructor(
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.getSystemMessage();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    protected getSystemMessage(): void {
        const subscription = this.messageService.getList(
            {
                limit: 1,
                type: 'system'
            },
            environment.caching.default
        ).subscribe(data => {
            this.message = data?.data?.[0] ?? null;
        });
        this.subscriptions.push(subscription);
    }
}
