import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnDestroy,
    AfterContentInit,
    Output,
    ContentChildren,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import { User } from '../../models/user/user.model';
import { Franchise } from '../../models/franchise.model';
import { UploadComponentType } from '../upload/upload.component';
import { AvatarSize } from '../avatar/avatar.component';
import { translate } from '@ngneat/transloco';
import { I18nService } from '../../services/i18n.service';
import { Subscription, filter } from 'rxjs';

@Component({
    selector: 'upload-avatar',
    templateUrl: './upload-avatar.component.html',
    styleUrls: ['./upload-avatar.component.scss']
})
export class UploadAvatarComponent implements OnInit, OnDestroy, AfterContentInit {
    protected subscriptions: Subscription[] = [];

    @ContentChildren(ItemTemplateDirective) templates?: QueryList<any>;

    @Input() disabled: boolean = false;
    @Input() avatar: any = {};
    @Input() loading: boolean = false;
    @Input() withRemove: boolean = true;
    @Input() sideRemove: boolean = false;
    @Input() maxSize: number = 30;
    @Input() labelAdd: string|null = null;
    @Input() labelRemove: string|null = null;
    @Input() user: User = new User;
    @Input() partner: Franchise = new Franchise;
    @Input() type: UploadComponentType = 'avatar';
    @Input() size: AvatarSize = 'small';

    @Output() onFinished: EventEmitter<any> = new EventEmitter();
    @Output() onUploaded: EventEmitter<any> = new EventEmitter();
    @Output() onError: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    templateDefault?: TemplateRef<any>;
    templateLoading?: TemplateRef<any>;
    templateAdditional?: TemplateRef<any>;

    constructor(
        private i18nService: I18nService,
    ) { }

    ngOnInit(): void {
        const subscription = this.i18nService.onLocaleChanged()
            .pipe(filter(locale => locale.language?.length > 0))
            .subscribe(locale => {
                this.labelAdd = this.labelAdd ? this.labelAdd : translate('Добави снимка');
                this.labelRemove = this.labelRemove ? this.labelRemove : translate('Премахни снимка');
            });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch (item.getType()) {
                case 'default':
                    this.templateDefault = item.template;
                    break;
                case 'loading':
                    this.templateLoading = item.template;
                    break;
                case 'additional':
                    this.templateAdditional = item.template;
                    break;
            }
        });
    }


    onFilesFinished(event: any): void {
        this.onFinished.emit(event);
    }

    onFileUploaded(event: any): void {
        this.onUploaded.emit(event);
    }

    onFileError(event: any): void {
        this.onError.emit(event);
    }

    onAvatarRemove(event: any): void {
        if (!this.disabled) {
            this.onRemove.emit();
        }
    }
}
