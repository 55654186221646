import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';

export interface ModalSortItemsComponentItem {
    title: string;
    value: any;
    children?: ModalSortItemsComponentItem[];
};

@Component({
    selector: 'modal-sort-items',
    templateUrl: './modal-sort-items.component.html',
    styleUrls: ['./modal-sort-items.component.scss']
})
export class ModalSortItemsComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected innerItems: Array<ModalSortItemsComponentItem> = [];
    protected innerMultiLevel: boolean = false;

    @Output() sort: EventEmitter<{event: CdkDragDrop<string[]>, item: ModalSortItemsComponentItem}> = new EventEmitter();
    @Output() sortAll: EventEmitter<{parent?: ModalSortItemsComponentItem|null, items: ModalSortItemsComponentItem[]}> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();

    @Input() withConfirm: boolean = false;
    @Input() headline: string = translate('Подреждане');
    @Input() skippable: boolean = true;
    @Input() loading: boolean = false;

    @Input()
    set items(val: Array<ModalSortItemsComponentItem>) {
        if (this.innerItems !== val) {
            this.innerItems = val;

            this.visibleItems = this.innerItems?.slice();

            this.handleMultilevel(this.innerItems);
        }
    }
    get items(): Array<ModalSortItemsComponentItem> {
        return this.innerItems;
    }

    @Input()
    set multilevel(val: boolean) {
        if (this.innerMultiLevel !== val) {
            this.innerMultiLevel = val;
            this.handleMultilevel(this.items);
        }
    }
    get multilevel(): boolean {
        return this.innerMultiLevel;
    }

    isDirty: boolean = false;
    visibleItems: ModalSortItemsComponentItem[] = [];
    selectedItem?: ModalSortItemsComponentItem|null = null;
    categories: ModalSortItemsComponentItem[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    onHidePopup(event?: any): void {
        this.close.emit();
    }

    trackItem(index: number, item: ModalSortItemsComponentItem) {
        return item.value;
    }

    onDrop(event: CdkDragDrop<string[]>): void {
        this.isDirty = event.previousIndex !== event.currentIndex ? true : this.isDirty;
        moveItemInArray(this.visibleItems, event.previousIndex, event.currentIndex);
        this.sort.emit({event, item: event.item.data || null});
    }

    onSubmit(event?: any): void {
        this.sortAll.emit({parent: this.selectedItem ?? null, items: this.visibleItems});
    }

    onCategoryChange(event?: {originalEvent?: any, value?: any}): void {
        this.isDirty = false;

        this.selectedItem = !event?.value
            ? null
            : this.items?.find(item => item.value === event?.value);

        this.visibleItems = !event?.value
            ? this.items?.slice()
            : (this.items?.find(item => item.value === event?.value)?.children?.slice() || []);
    }

    protected handleMultilevel(items: ModalSortItemsComponentItem[] = []): void {
        if (this.multilevel) {
            this.categories = [];
            items?.map(item => {
                this.categories.push(item);

                if (item.children?.length) {
                    item.children?.map(subitem => {

                        this.categories.push({
                            ...subitem,
                            title: ' -- ' + subitem.title,
                        });
                    });
                }
            });
            this.categories.unshift({
                title: translate('< Основен списък >'),
                value: null,
            });
        }
    }
}
