<ng-container *ngIf="showContents">
    <p-toast></p-toast>

    <div *ngIf="!loading" class="cover" [class.signing]="isUserGuest">
        <app-language-switcher mode="dropdown" *ngIf="isUserGuest"></app-language-switcher>
        <app-header *ngIf="!isUserGuest"></app-header>
        <div [ngClass]="{
            'container-fluid': !isUserGuest,
            'container-lg py-1 py-sm-3 py-md-5 cover-container': isUserGuest
        }">
            <ng-container *ngTemplateOutlet="routing"></ng-container>
        </div>
    </div>

    <modal-terms (onClose)="showInstructions = true"></modal-terms>
    <modal-instructions *ngIf="showInstructions"></modal-instructions>
</ng-container>

<ng-container *ngIf="!showContents">
    <ng-container *ngTemplateOutlet="routing"></ng-container>
</ng-container>

<ng-template #routing>
    <router-outlet></router-outlet>
</ng-template>

<modal-confirm  *ngIf="showRefreshAskModal"
                (onAction)="onModalRefresh($event)"
                [closable]="false"
                body=""
>
    <p class="mt-4">{{ 'Системата е обновена с нова версия. Желаете ли презареждане на страницата?' | transloco }}</p>
</modal-confirm>
