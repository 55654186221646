import { UserImportResultSummary } from "./user-import-result-summary.model";
import { User } from "./user.model";

export class UserImportResult {
    users?: User[];
    summary?: UserImportResultSummary;

    static fromJson(fields: any) {
        let item = Object.assign(new this(), fields);
        item.users = fields?.users?.map((item: any) => User.fromJson(item));
        item.summary = UserImportResultSummary.fromJson(fields?.summary);
        return item;
    }
}
