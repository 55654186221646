export type CourseSpotlightStatus = ('active'|'inactive');

export class CourseSpotlight {
    id?: number;
    title?: string;
    name?: string;
    status?: CourseSpotlightStatus;

    static fromJson(fields: any): CourseSpotlight {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
