<upload [type]="type"
        class="text-nowrap"
        [disabled]="loading"
        [maxSize]="maxSize * 1024"
        [files]="1"
        [disabled]="disabled"
        (onFileUploaded)="onFileUploaded($event)"
        (onFileError)="onFileError($event)"
        (onFilesFinished)="onFilesFinished($event)"
>
    <ng-template itemTemplate="default" let-isButtonHover>
        <ng-container *ngIf="templateDefault">
            <ng-container *ngTemplateOutlet="templateDefault"></ng-container>
        </ng-container>

        <ng-container *ngIf="!templateDefault">
            <avatar *ngIf="avatar?.url?.length"
                    size="medium"
                    class="my-2 d-block text-center"
                    className="d-inline-block"
                    [size]="size"
                    [photo]="avatar?.url"
                    [user]="user || null"
            ></avatar>

            <div class="upload" [class.hover]="isButtonHover" *ngIf="!avatar?.url?.length">
                <div class="icon mb-3"><i class="far fa-user-circle fa-fw fa-2x text-primary"></i></div>
                <div class="info">
                    {{ labelAdd }}<br />
                    {{ '\{{ maxSize }\} MB макс.' | transloco:{maxSize: maxSize} }}
                </div>
            </div>
        </ng-container>
    </ng-template>

    <ng-template itemTemplate="loading">
        <ng-container *ngIf="templateLoading">
            <ng-container *ngTemplateOutlet="templateLoading"></ng-container>
        </ng-container>


        <div class="text-center mx-4 my-2" *ngIf="!templateLoading">
            <i class="d-inline-block fas fa-spinner fa-4x fa-pulse mx-auto text-secondary mx-auto"></i>
        </div>
    </ng-template>

    <ng-template itemTemplate="additional">

        <ng-container *ngIf="templateAdditional">
            <ng-container *ngTemplateOutlet="templateAdditional"></ng-container>
        </ng-container>

        <button *ngIf="!templateAdditional && avatar?.url && withRemove" type="button"
                [disabled]="disabled"
                class="primary-btn btn-round align-top text-nowrap"
                (click)="onAvatarRemove($event)"
        ><i class="fa fa-fw fa-trash"></i> {{ labelRemove }}</button>
        <i class="fa-solid fa-circle-xmark remove-btn text-danger" (click)="onAvatarRemove($event)" *ngIf="sideRemove && avatar?.url"></i>
    </ng-template>
</upload>
