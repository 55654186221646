import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'datetimeShort',
})
export class DatetimeShortPipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe
    ) {
    }

    transform(value?: Date): string|null {
        return this.datePipe.transform(value, 'd MMM YY HH:mm'.split(' ').join('\u00A0'));
    }

}
