<upload [type]="type"
        class="text-nowrap"
        [disabled]="loading"
        [maxSize]="maxSize * 1024"
        [files]="files"
        [disabled]="disabled"
        (onFileUploaded)="onFileUploaded($event)"
        (onFileError)="onFileError($event)"
        (onFilesFinished)="onFilesFinished($event)"
>
    <ng-template itemTemplate="default" let-isButtonHover>
        <ng-container *ngIf="!templateDefault">
            <div class="upload" [class.hover]="isButtonHover">
                <img *ngIf="preview" [src]="preview" class="img-thumbnail" />

                <ng-container *ngIf="!preview">
                    <div class="icon mb-3"><i class="fas fa-camera fa-fw text-primary fa-2x"></i></div>
                        <div class="info">
                            {{ labelDefault }}<br />
                            {{ '\{{ maxSize }\} MB макс.' | transloco:{maxSize: maxSize} }}
                        </div>
                </ng-container>
            </div>
        </ng-container>

        <div *ngIf="templateDefault">
            <ng-container *ngTemplateOutlet="templateDefault, context: {$implicit: isButtonHover}"></ng-container>
        </div>
    </ng-template>

    <ng-template itemTemplate="loading">
        <div class="text-center mx-4 my-2" *ngIf="!templateLoading">
            <i class="d-inline-block fa-solid fa-spinner fa-4x fa-pulse mx-auto text-secondary mx-auto"></i>
        </div>

        <div *ngIf="templateLoading">
            <ng-container *ngTemplateOutlet="templateLoading"></ng-container>
        </div>
    </ng-template>

    <ng-template itemTemplate="additional">
        <div *ngIf="preview?.length" class="d-flex align-items-center justify-content-center">

            <a *ngIf="preview?.length"
                    type="button"
                    class="btn btn-primary btn-sm btn-round align-top text-nowrap m-2"
                    [href]="preview"
                    target="_blank"
            ><i class="fa-solid fa-fw fa-eye "></i> {{ labelOpen }}</a>

            <button *ngIf="preview?.length && withRemove"
                    type="button"
                    [disabled]="disabled"
                    class="btn btn-danger btn-sm btn-round align-top text-nowrap m-2"
                    (click)="onFileRemove($event)"
            ><i class="fa-solid fa-fw fa-trash"></i> {{ labelRemove }}</button>
        </div>
    </ng-template>

</upload>
