<ng-container *ngIf="(available?.length ?? 0) > 1">
    <ng-container *ngIf="mode === 'codes' || mode === 'labels'">
        <a href="#"
            (click)="onSelectLanguage($event, item)"
            *ngFor="let item of available"
            [title]="item.label"
            [class.active]="active === item.id"
        >
            {{ mode === 'codes' ? activeCode : item.label }}
        </a>
    </ng-container>

    <p-dropdown *ngIf="mode === 'dropdown'"
                [options]="available"
                [(ngModel)]="active"
                optionLabel="label"
                optionValue="id"
                (onChange)="onChangeDropdown($event)"
    >
        <ng-template pTemplate="selectedItem" let-item>
            <div class="d-flex align-items-center justify-content-start" *ngIf="item">
                <i class="fa-solid fa-earth-americas fa-fw me-2 text-light d-none d-lg-inline-block"></i>
                <div class="pb-1 d-none d-lg-block">{{ item.label }}</div>
                <div class="pb-1 d-lg-none text-uppercase">{{ activeCode }}</div>
            </div>
        </ng-template>

        <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center justify-content-start">
                <i class="fas fa-check fa-fw me-1" *ngIf="active === item.id"></i>
                <i class="fas fa-fw me-1" *ngIf="active !== item.id"></i>
                <div>{{ item.label }}</div>
            </div>
        </ng-template>
    </p-dropdown>


</ng-container>


