import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransferDataService {

    private sharedData: Subject<any> = new Subject<any>();
    sharedData$: Observable<any> = this.sharedData.asObservable();

    constructor() { }


    setData(updatedData: any) {
        this.sharedData.next(updatedData);
    }
}
