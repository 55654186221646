import { StudentGroupVerificaton } from "../student-group-verification";
import { UserInfo } from "./user-info.model";
import { UserRole, UserRoleName } from "./user-role.model";
import { GroupCourse } from "./user-group-course.model";
import { Paginate } from "../paginate.model";
import { Payment } from "../payment.model";
import { Certificate } from "../certificate/certificate.model";
import { FranchiseShort } from "../franchise-short.model";
import { ModuleTestResult } from "../module-test-result.model";
import { PointOther } from "../scoreboard/point-other.model";
import { HomeworkFile } from "../homework-file.model";
import { UserRegistration } from "./user-registration.model";

export type UserStatus = ('active'|'inactive');
export type UserApproval = ('pending'|'approved'|'rejected');

export class User {
    static readonly REGEX_LINKEDIN = /(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/i;

    id?: number;
    name?: string;
    surname?: string;
    lastname?: string;
    cv?: {
        id: number;
        url: string;
    };
    email?: string;
    phone?: string;
    image?: string;
    status?: UserStatus;
    created?: string;
    updated?: string;
    kid?: boolean;
    role?: UserRole;
    info?: UserInfo;
    verification: StudentGroupVerificaton = new StudentGroupVerificaton;
    franchise?: FranchiseShort;
    certificates?: Certificate[];
    groups?: GroupCourse[];
    homeworks?: HomeworkFile[];
    payments?: Payment[];
    points?: PointOther[];
    tests?: ModuleTestResult[];
    registrations?: UserRegistration[];
    locale?: string;
    tz_offset?: number;


    static fromJson(fields: any): User {
        let item = Object.assign(new this(), fields);
        item.role = UserRole.fromJson(fields?.role);
        item.info = UserInfo.fromJson(fields?.info);
        item.verification = StudentGroupVerificaton.fromJson(fields?.verification);
        item.franchise = FranchiseShort.fromJson(fields?.franchise);
        item.certificates = fields?.certificates?.map((item: any) => Certificate.fromJson(item));
        item.groups = fields?.groups?.map((item: any) => GroupCourse.fromJson(item));
        item.homeworks = fields?.homeworks?.map((item: any) => HomeworkFile.fromJson(item));
        item.payments = fields?.payments?.map((item: any) => Payment.fromJson(item));
        item.tests = fields?.tests?.map((item: any) => ModuleTestResult.fromJson(item));
        item.registrations = fields?.registrations?.map((item: any) => UserRegistration.fromJson(item));

        const pointsArray = Array.isArray(fields?.points) ? fields.points : [];
        item.points = pointsArray.map((pointItem: any) => PointOther.fromJson(pointItem));


        return item;
    }

    /**
     * Determine if user is one of the roles in the listing
     * @param roles
     * @returns
     */
    can(roles: UserRoleName[]): boolean {
        return (roles || []).indexOf(this.role?.name as UserRoleName ?? '') !== -1;
    }

    /**
     * Check if user is guest
     */
    isGuest(): boolean {
        return !(this.name || '').length
            || !(this.id || 0)
            || !this.role?.id;
    }

    getFullName(): string {
        return [
            this.name || '',
            this.lastname || ''
        ].join(' ');
    }
}
