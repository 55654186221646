<div class="news mb-4" *ngIf="message?.message?.length">
    <div class="new-bubble d-flex p-3">
        <div class="message-icon">
            <i class="icon-email"></i>
        </div>
        <div class="px-2 d-flex flex-column">
            <p>{{ message?.message }}</p>
            <br />
            <small class="mt-auto text-light">{{ message?.date | dateLocale:'short' }}</small>
        </div>
        <div class="align-self-center academy-icon ms-auto">
            <div class="icon-academy-a"></div>
        </div>
    </div>
</div>
