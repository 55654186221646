export class Contact {
    name?: string;
    email?: string;
    phoneNumber?: string;
    topic?: string;
    message?: string;
    recaptcha?: string;

    static fromJson(fields: any): Contact {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
