import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'sidebar-cv',
    templateUrl: './cv.component.html',
    styleUrls: ['./cv.component.scss']
})
export class SidebarCvComponent implements OnInit {
    user = new User();

    constructor(
        private authService: AuthService,
    ) {
        this.user = this.authService.getUser() ?? this.user;
    }

    ngOnInit(): void {
    }
}
