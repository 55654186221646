<a [routerLink]="'/courses/' + group?.id" *ngIf="type === 'course'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<a [routerLink]="'/groups/' + group?.id" *ngIf="type === 'show'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<a [routerLink]="'/homework/groups/' + group?.id" *ngIf="type === 'homework'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<div *ngIf="type === 'view'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</div>

<ng-template #item>
    <div class="course-card d-flex flex-column flex-lg-row my-3" [class.card-link]="type === 'show' || type === 'homework'">
        <div class="col-lg-6 col-12">
            <div class="course-icon">
                <img [src]="group?.course?.image" [alt]="group?.title || ('Курс' | transloco)">
            </div>
        </div>
        <div class="col-lg-6 col-12 p-lg-3">
            <div class="course-cont d-flex flex-column align-items-start justify-content-center">
                <h3 class="mb-3">{{group?.course?.title}}</h3>

                <h4>{{ group?.title }}</h4>

                <p *ngIf="group?.parsed_date_start && group?.parsed_date_to">
                    {{group?.parsed_date_start|dateLocale:"d MMMM y"}} - {{group?.parsed_date_to|dateLocale:"d MMMM y"}}
                </p>

                <div class="pt-2 pb-3" *ngIf="group?.facebook?.length && type === 'view'">
                    <a target="_blank" [href]="group?.facebook" (click)="preventPropagation($event)" class="my-2 py-3 px-0" >
                        <i class="fa-brands fa-square-facebook fa-fw fa-lg"></i>

                        {{ 'Група на курса във Facebook' | transloco }}
                    </a>
                </div>

                <div class="my-auto d-flex" *ngIf="type !== 'homework'">
                    <div class="primary-btn d-lg-block d-none py-2 px-3 my-2" *ngIf="type === 'show' || type === 'course'">
                        <span>{{ 'Към курса' | transloco }}</span>
                    </div>
                    <a target="_blank" [href]="'groups/' + group?.id + '/live'" (click)="preventPropagation($event)"
                        class="live-btn my-2 py-3 px-0" *ngIf="group?.live?.online && group?.live?.zoom?.id">
                        <span class="sr-only">{{ 'На живо' | transloco }}</span>
                    </a>
                    <a target="_blank" [href]="group?.live?.google" (click)="preventPropagation($event)"
                        class="live-btn my-2 py-3 px-0" *ngIf="group?.live?.online && group?.live?.google?.length && !group?.live?.zoom?.id">
                        <span class="sr-only">{{ 'На живо' | transloco }}</span>
                    </a>
                </div>

                <ng-content></ng-content>
            </div>
        </div>
    </div>
</ng-template>
