import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, share } from 'rxjs';
import { ApiService } from './api.service';
import { I18nService, I18nServiceLocale } from './i18n.service';
import { LocaleInput } from '@fullcalendar/core';

import bg from '@fullcalendar/core/locales/bg';
import en from '@fullcalendar/core/locales/en-gb';
import ru from '@fullcalendar/core/locales/ru';
import uk from '@fullcalendar/core/locales/uk';

import { Event } from '../models/event/event.model';
import { EventItem } from '../models/event/event-item.model';

export interface CalendarServiceIndex {
    start?: string;
    end?: string;
    groups?: string[]|string;
};

export type CalendarInputEventType = (
    'program'
    | 'holiday'
);

export type CalendarInputEventRange = (
    'daily'
    | 'continuous'
);

export type CalendarInputEventSequence = (
    'enable'
    | 'disable'
);

export type CalendarInputEventDayOfWeek = (
    'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday'
);
export interface CalendarInputEvent {
    group: number;
    type: CalendarInputEventType;
    range: CalendarInputEventRange;
    sequence?: CalendarInputEventSequence;
    title: string;
    start: string;
    end: string;
    days_of_week?: CalendarInputEventDayOfWeek[];
    exclude_days?: string[];
};

export interface CalendarInputEventItem {
    title?: string;
    start?: string;
    end?: string;
    type?: CalendarInputEventType;
};

export interface CalendarServiceLocale extends LocaleInput {
    week?: {[key: string]: number };
    details?: I18nServiceLocale;
}

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    protected subjectLanguage: BehaviorSubject<CalendarServiceLocale|null> = new BehaviorSubject<CalendarServiceLocale|null>(null)

    availableLocales: {[key: string]: CalendarServiceLocale} = {
        bg,
        en,
        ru,
        uk,
    };

    constructor(
        protected api: ApiService,
        protected i18nService: I18nService,
    ) {
        i18nService.onLocaleChanged().subscribe(locale => {
            this.subjectLanguage.next(this.getLocale());
        });
    }

    getList(filter?: CalendarServiceIndex, maxCacheTime?: number): Observable<{data: Event[]}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && (filter['groups'] = Array.isArray(filter?.groups) ? filter?.groups?.join(',') : filter?.groups);
        // filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CareerServiceSortType);
        // filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(',') as any);

        return this.api.get('/events', {params: filter}, maxCacheTime).pipe(
            map(response => {
                response.data = response.data?.map((item: any) => Event.fromJson(item));
                return response;
            })
        );
    }

    /**
     * Get a single event item
     * @param id
     * @returns
     */
    getItem(id: number): Observable<{data: EventItem}> {
        return this.api.get('/events/' + id).pipe(
            map(data => {
                data.data = EventItem.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Add a new event
     * @returns
     */
    add(data: CalendarInputEvent): Observable<{data: Event}> {
        return this.api.post('/events', data).pipe(
            map(data => {
                data.data = Event.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing event
     * @returns
     */
    edit(id: number, data: CalendarInputEventItem): Observable<{data: EventItem}> {
        return this.api.post('/events/' + id, data).pipe(
            map(data => {
                data.data = EventItem.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete an existing event
     * @param id
     * @returns
     */
    delete(id: number):  Observable<any> {
        return this.api.delete('/events/' + id);
    }

    /**
     * Delete an existing event
     * @param id
     * @returns
     */
    deleteMultiple(ids: number[]):  Observable<any> {
        return this.api.delete('/events?ids=' + ids.join(','));
    }

    getLocale(): CalendarServiceLocale {
        const locale = this.i18nService.getLocaleDetails();
        return {
            ...this.availableLocales[locale.language] ?? this.availableLocales['bg'],
            ...{details: this.i18nService.getLocaleDetails()},
        };
    }

    /**
     * When a locale is changed
     * @returns
     */
    onLocaleChanged(): Observable<CalendarServiceLocale|null> {
        return this.subjectLanguage.asObservable().pipe(share());
    }
}
