import { GroupShort } from "./group-short.model";
import { HomeworkMessage } from "./homework-message.model";
import { Homework } from "./homework.model";
import { User } from "./user/user.model";

export class HomeworkFile {
    id?: number;
    name?: string;
    filename?: string;
    description?: string;
    points?: number;
    checked?: number;
    date?: string;
    homework?: Homework;
    group?: GroupShort;
    user?: User;
    message?: HomeworkMessage;
    messages?: HomeworkMessage[];

    static fromJson(fields: any): HomeworkFile {
        let item = Object.assign(new this(), fields);
        item.homework = Homework.fromJson(item?.homework);
        item.group = GroupShort.fromJson(item?.group);
        item.user = User.fromJson(item?.user);
        item.messages = fields?.messages?.map((messages: any) => HomeworkMessage.fromJson(messages));
        item.message = fields?.message ? HomeworkMessage.fromJson(fields?.message) : null;
        return item;
    }
}
