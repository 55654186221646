import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

import { environment } from '../environments/environment';
import { RuntimeService } from './shared/services/runtime.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

    constructor(
        private http: HttpClient,
        private runtimeService: RuntimeService,
    ) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=` + this.runtimeService?.getBuild());
    }
}

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: environment.locales.available?.map((item: any) => {
                    return {
                        id: item.id ?? '',
                        label: item.label ?? '',
                    }
                }),
                defaultLang: environment.locales.default,
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            })
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        provideTranslocoMessageformat({
            locales: environment.locales.available?.map((item: any) => item.id.replace('_', '-')),
        }),
    ]
})
export class TranslocoRootModule {}
