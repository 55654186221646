import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../services/payment.service';
import { Payment } from '../../models/payment.model';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.scss']
})
export class ModalPaymentComponent implements OnInit {
    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onAction: EventEmitter<{action: string, paid: boolean}> = new EventEmitter();
    @Input() headline: string = translate('Плащане на курс');

    @Input() closable: boolean = true;
    @Input() amount: number = 0;
    @Input() paymentId: number = 0;
    @Input() title: string|undefined = 'Academy';
    @Input() description: string|undefined = translate('Плащане');

    protected subscriptions: Subscription[] = [];
    payPalConfig?: IPayPalConfig;

    showSuccess: boolean = false;
    showCancel: boolean = false;
    showError: boolean = false;
    loading: boolean = false;
    paid: boolean = false;
    paymentForm: string = '';
    payment: Payment = new Payment();

    message: string = '';
    messageType: ('error'|'success'|'warning') = 'error';

    constructor(
        protected paymentService: PaymentService,
    ) { }

    ngOnInit(): void {
        this.initConfig();
        this.getPayment();
    }

    private getPayment():void {
        const subscription = this.paymentService.getItem(this.paymentId).subscribe({
            next: (data: any) => {
                this.payment = data.data;
                this.headline += ' ' + this.payment.group?.course?.title ?? '';
            }
        });

        this.subscriptions.push(subscription);
    }

    private initConfig(): void {
        const price: string = this.amount.toString() ?? '1';

        this.payPalConfig = {
            currency: 'EUR',
            clientId: environment.services.paypal.key,
            createOrderOnClient: (data) => {
                this.loading = true;

                return {
                    intent: 'CAPTURE',
                    purchase_units: [{
                        description: this.description,
                        custom_id: this.paymentId.toString() ?? '0',
                        amount: {
                            currency_code: 'EUR',
                            value: price,
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: price
                                }
                            }
                        },
                        items: [{
                            name: this.title,
                            quantity: '1',
                            category: 'DIGITAL_GOODS',
                            unit_amount: {
                                currency_code: 'EUR',
                                value: price,
                            },
                        }]
                    }]
                } as ICreateOrderRequest;
            },
            advanced: {
                commit: 'true'
            },
            style: {
                layout: 'horizontal',
                tagline: false
            },
            onApprove: async (data, actions) => {
                this.messageType = 'warning';
                this.message = translate('Плащането се обработва...');

                try {
                    await actions.order.get().then((details: any) => {
                        return details.id;
                    });
                } catch (e) {
                    this.onPaymentError(e);
                }
            },
            onClientAuthorization: (order) => {
                this.purchase(order.id);
            },
            onCancel: () => {
                this.loading = false;
            },
            onShippingChange: (data, actions) => {
                return actions.resolve();
            },
            onError: error => this.onPaymentError(error),
        };
    }

    purchase(order: string): void {
        this.paymentService.paypalPurchase(this.paymentId, order).subscribe({
            next: (data: any) => {
                this.messageType = 'success';
                this.message = translate('Плащането беше завършено успешно.');
                this.paid = true;

                setTimeout(() => {
                    this.onCloseAction();
                    this.loading = false;
                }, 3000);
            },
            error: error => this.onPaymentError(error)
        });
    }

    onBuyConfirmed(event: Event): void {
        if (this.loading) {
            return;
        }
        this.loading = true;

        this.paymentService.myposCreate(this.paymentId).subscribe({
            next: (data: any) => {
                this.paymentForm = data.replace(/<\/body>/g, '').replace(/<body[^\>]+>/g, '');
                setTimeout(() => {
                    this.formRef?.nativeElement?.querySelector("form").setAttribute('target', '_blank');
                    this.formRef?.nativeElement?.querySelector("form").submit();

                    this.loading = false;
                }, 100);
            },
            error: error => {
                this.onPaymentError(error);
                this.loading = false;
            }
        })
    }

    onPaymentError(error?: any): void {
        this.messageType = 'error';
        this.message = translate('Възникна грешка при плащането!');
        this.loading = false;
    }

    onCloseAction(e?: any): void {
        this.onAction.emit({action: 'close', paid: this.paid});
    }

    ngOnDestroy() {
        this.subscriptions.map(item => item?.unsubscribe());
    }
}
