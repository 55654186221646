export class UserInfo {
    birthday?: string;
    description?: string;
    position?: string;
    education?: string;
    experience?: string;
    facebook?: string;
    github?: string;
    grade?: number;
    languages?: string;
    linkedin?: string;
    medical_notes?: string;
    parent_email?: string;
    parent_name?: string;
    parent_phone?: string;
    school?: string;
    tiktok?: string;
    town?: {
        "id": number,
        "name": string,
    }
    website?: string;

    static fromJson(fields: any): UserInfo {
        let item = Object.assign(new this(), fields);
        return item;
    }

}
