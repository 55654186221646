import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sidebar-classes',
    templateUrl: './classes.component.html',
    styleUrls: ['./classes.component.scss']
})
export class SidebarClassesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
