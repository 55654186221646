export type GroupVideoStatus = ('active'|'inactive');

export class GroupVideo {
    id?: number;
    video?: string;
    description?: string;
    status?: GroupVideoStatus;
    created?: string;
    updated?: string;


    static fromJson(fields: any): GroupVideo {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
