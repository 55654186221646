import { ModuleTestQuestion } from "./module-test-question.model";
import { ModuleTestResult } from "./module-test-result.model";
import { ModuleTestType } from "./module-test-type.model";

export class ModuleTest {
    id?: number;
    name?: string;
    status?: string;
    profession?: string;
    specialty?: string;
    duration?: number;
    course?: string;
    questions?: ModuleTestQuestion[];
    results?: ModuleTestResult[];
    result?: ModuleTestResult;
    has_practice?: boolean;
    max_points?: number;
    type?: ModuleTestType;
    created_at?: string;
    final_result?: number;
    is_final?: boolean;
    is_midterm?: boolean;

    static fromJson(fields: any): ModuleTest {
        let item = Object.assign(new this(), fields);
        item.type = ModuleTestType.fromJson(fields?.type);
        item.is_final = item?.time?.title === 'final';
        item.is_midterm = item?.time?.title === 'midterm';
        item.result = ModuleTestResult.fromJson(fields?.result);
        item.results = fields?.results?.map((item: any) => ModuleTestResult.fromJson(item));
        return item;
    }
}
