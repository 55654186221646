export const environment = {
    name: 'global',

    production: false,
    application: 'LetsShakeHands',
    domain: 'localhost',
    scheme: 'http',
    port: 8000,

    pagination: {
        default: 25,
        payments: 5,
        courses: 2,
        jobs: 12,
        home: 5,
    },

    debounce: {
        // default Search field debounce timeout in ms
        default: 750,
    },

    routes: {
        home: '/',
        guest: '/auth/login',
    },

    services: {
        recaptcha: {
            key: '6LfSOCkpAAAAAN2KdNfEg8RM71rvep4TRGQfji2j',
        },
        paypal: {
            key: 'AW_fxPJSdjEMAL3SAwWy6Q7wjnU5uzNoqARO-9xpkmvz25PrppVoBHDQrSsABkhMS6X25IPP-Z_OafcQ',
        },
        firebase: {
            apiKey: "AIzaSyC5ItStkJID_8QVD_d7M_AfU1f7LRDQ8oY",
            authDomain: "let-s-shake-hands-7bc67.firebaseapp.com",
            databaseURL: "https://let-s-shake-hands-7bc67-default-rtdb.firebaseio.com",
            projectId: "let-s-shake-hands-7bc67",
            storageBucket: "let-s-shake-hands-7bc67.appspot.com",
            messagingSenderId: "1096049163566",
            appId: "1:1096049163566:web:e9f851b9e8dcf25d990ebf"

        }
    },

    caching: {
        default: 5 * 60 * 1000, // 5 min
        sidebar: 15 * 60 * 1000, // 15 min. for most of sidebar items
    },

    features: {
        // Later will turn it on - it's annoying during development
        recaptcha: false,
    },

    locales: {
        // all available locales
        // @note: Do not translate labels!
        // @note2: DON'T use language-only codes!
        available: [{
            'id': 'bg_BG',
            'label': "Български език",
        }, {
            'id': 'en_US',
            'label': "English Language",
        }, {
            'id': 'ru_RU',
            'label': "Русский язык",
        }, {
            'id': 'uk_UA',
            'label': "Українська мова",
        }],

        // Default locale as fallback
        default: 'bg_BG'
    },
};
