import { TestResultAnswer } from "./module-test-result-answer.model";

export class ModuleTestResult {
    id?: number;
    exam?: number;
    timeout?: number;
    correct?: number;
    result?: number;
    created_at?: string;
    expired_at?: string;
    finished_at?: string;
    current?: string;
    is_expired?: string;
    remain_second?: number;
    parent_id?: number;
    type?: number;
    answers?: TestResultAnswer[];

    static fromJson(fields: any): ModuleTestResult {
        let item = Object.assign(new this(), fields);
        item.answers = fields?.answers?.map((item: any) => TestResultAnswer.fromJson(item));
        return item;
    }
}
