<page-wrap [forceShrinkWidth]="forceShrinkWidth">
    <a class="text-center mb-2 mb-sm-4 d-block px-5" routerLink="/" *ngIf="!content">
        <img src="/assets/img/logos/logo.svg" alt="Advance Academy" class="w-100 mx-auto d-inline-block" />
    </a>

    <div class="headline" *ngIf="!content">
        <h1 class="text-center">{{ 'Потребителски контролен панел' | transloco }}</h1>
    </div>

    <ng-container *ngTemplateOutlet="content"></ng-container>

    <div class="container" *ngIf="forceShrinkWidth && !loading">
        <div class="row">
            <div class="col-12 col-lg-6 mx-auto">
                <div class="box"><ng-container *ngTemplateOutlet="box"></ng-container></div>
            </div>
        </div>
    </div>
    <div class="box" *ngIf="!forceShrinkWidth && !loading"><ng-container *ngTemplateOutlet="box"></ng-container></div>

    <p class="text-center my-3">
        {{ 'При възникнали въпроси или проблеми:' | transloco }}
        <!-- @ToDo: Load those links from franchise request -->
        <a href="https://t.me/+86ohSNPnHEY0MDEy" class="text-nowrap" target="_blank">Telegram Group</a><br />
        <a href="https://platform.letshakehands.com" class="text-nowrap">platform.letshakehands.com</a>
    </p>
</page-wrap>

<ng-template #box>
    <ng-content></ng-content>
</ng-template>
