import { CourseShort } from "./course-short.model";
import { GroupPaymentType } from "./group-payment-type.model";
import { Group } from "./group.model";
import { User } from "./user/user.model";

export class Payment {
    id?: number;
    price?: {
        bgn?: number;
        eur?: number;
    };
    description?: string;
    method?: string;
    status?: string;
    due?: string;
    created?: string;
    updated?: string;
    type?: GroupPaymentType;
    group?: Group;
    course?: CourseShort;
    user?: User;

    static fromJson(fields: any): Payment {
        let item = Object.assign(new this(), fields);
        item.type = GroupPaymentType.fromJson(fields?.type);
        item.group = Group.fromJson(fields?.group);
        item.course = CourseShort.fromJson(fields?.course);
        item.user = User.fromJson(fields?.user);
        return item;
    }
}
