import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, TemplateRef, QueryList, ContentChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export type ListingTableSortDirection = ('asc'|'desc');

export type ListingTableComponentColumnFilterType = ('search'|'date'|'dates'|'list'|'range');

export interface ListingTableComponentColumnFilter {
    type: ListingTableComponentColumnFilterType;
    value: any;
    field: string;

    // specific configurations based on .type
    search?: {
        min?: number;
        max?: number;
    },
    date?: {
        from?: null|Date;
        to?: null|Date;
        skip?: Date[];
        withTime?: boolean;
        hours?: (12|24|null);
    };
    dates?: {
        from?: null|Date;
        to?: null|Date;
        skip?: Date[];
        withTime?: boolean;
        hours?: (12|24|null);
    };
    list?: {
        withFilter?: boolean;
        multiple?: boolean;
        options: Array<{
            id: string|number;
            title: string;
            selected?: boolean;
            disabled?: boolean;
        }>;
    };
    range?: {
        from?: number;
        to?: number;
    }
}

export interface ListingTableComponentColumn {
    name?: string;
    field?: string;
    sortField?: string;
    buttons?: boolean;
    limitWidth?: boolean;
    align?: ('left'|'right'|'center'|'justify');
    filter?: ListingTableComponentColumnFilter;
    html?: (column?: any, row?: any) => string;
    defaultValue?: any;
    template?: boolean;
    direction?: ListingTableSortDirection;
    show?: boolean;
};

export interface ListingTableComponentAction {
    name?: string;
    action?: string;
    icon?: string;
    disabled?: boolean;
    confirm?: string;
    confirmTitle?: string;
    path?: string;
    separator?: boolean;
    btnClass?: string;
};

export interface ListingTableComponentSort extends ListingTableComponentColumn {
    field?: string;
    direction?: ListingTableSortDirection;
};

export interface ListingTableComponentFilterEvent {
    [column: string]: any;
};

export interface ListingTableComponentUpdateEvent {
    q: string;
    page?: number;
    filters?: any;
    sort?: ListingTableComponentSort[];
    limit?: number;
    prefix?: string;
};

@Component({
    selector: 'listing-table',
    templateUrl: './listing-table.component.html',
    styleUrls: ['./listing-table.component.scss']
})
export class ListingTableComponent implements OnInit, OnDestroy {
    @ContentChildren(ItemTemplateDirective) templates?: QueryList<any>;

    @Output('onAction') onAction: EventEmitter<{action: string, data: any}> = new EventEmitter();
    @Output('onUpdate') onUpdate: EventEmitter<ListingTableComponentUpdateEvent> = new EventEmitter();

    protected subscriptions: Subscription[] = [];
    protected innerStatus: string = '';
    protected innerRows: any[] = [];
    protected innerTotal: number = 0;
    protected innerColumns: ListingTableComponentColumn[] = [];
    protected sorting: string|any = null;
    protected restoredDataFromUrl: boolean = false;

    @Input() uuid: string = 'id';
    @Input() sequences: boolean = true;
    @Input() sequenceHeadline: string = '№';
    @Input() cumulativeSequences: boolean = true;
    @Input() perPage: number = environment?.pagination?.default || 10;
    @Input() limitPerPage: number = environment?.pagination?.default || 25;
    @Input() limits: number[] = [25, 50, 100, 250];

    @Input() searchDebounceTimeout: number = 750;
    @Input() hideSearchBar: boolean = false;
    @Input() hideActionBar: boolean = false;
    @Input() hideTotalBar: boolean = false;
    @Input() actionsDropdownWhen: string|null = null;

    @Input() search: string = '';
    @Input() actions: ListingTableComponentAction[] = [];
    @Input() sortMode: ('simple'|'full') = 'simple';
    @Input() withSorting: boolean = true;
    @Input() highlights: string[] = [];
    @Input() defaultCellValue: string = '-';

    @Input() withoutFolding: boolean = false;
    @Input() withWrappedColumn: boolean = true;

    @Input() maxNonDropdownActionsCount: number = 1;

    @Input() queryParamPrefix: string = '';

    @Input()
    set columns(val: ListingTableComponentColumn[]) {
        if (this.innerColumns !== val) {
            this.innerColumns = val;
        }
    }
    get columns(): ListingTableComponentColumn[] {
        return this.innerColumns;
    }

    @Input()
    set status(value: string) {
        if (this.innerStatus !== value) {
            this.innerStatus = value;
            this.computeLoading();
            this.computeHasError();
            this.computeNoData();
        }
    }
    get status(): string {
        return this.innerStatus;
    }

    @Input()
    set total(value: number) {
        if (this.innerTotal !== value) {
            this.innerTotal = value;

            const current = this.current;
            const lastPage =  ~~Math.ceil((this.innerTotal ?? 0) / (this.limit ?? 1));
            this.current = this.innerTotal && current > lastPage ? lastPage : this.current;

            this.computeNoData();

            if (this.current !== current) {
                this.updateRoute();
            }
        }
    }
    get total(): number {
        return this.innerTotal;
    }

    @Input()
    set rows(value: any[]) {
        if (this.innerRows !== value || this.innerRows?.length !== value?.length) {
            this.innerRows = value;
            this.computeNoData();
        }
    }
    get rows(): any[] {
        return this.innerRows;
    }

    searchDebounce: any = null;

    sort: ListingTableComponentSort[] = [];
    filters: ListingTableComponentFilterEvent = {};
    current: number = 1;
    limit: number = this.limitPerPage;
    currentCount: number = 0;
    showActionsMenu: boolean = false;
    loading: boolean = false;
    hasError: boolean = false;
    noData: boolean = true;
    isActionsSlotEmpty: boolean = false;
    templateCell?: TemplateRef<any>;

    confirmShow: boolean = false;
    confirmMessage: string|null = null;
    confirmMessageTitle: string|null = null;
    modalDateColumn: ListingTableComponentColumn|null = null;
    showModalDate: boolean = false;

    dropdownMenuTarget: HTMLElement|null = null;
    dropdownMenuShow: boolean = false;

    confirmCallbackResolve: any = () => {}
    confirmCallbackReject: any = () => {}

    protected updateEvent: any = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        const subscription = this.route.queryParams.subscribe((data: any) => {
            if (this.restoredDataFromUrl) {
                return;
            }

            if ((this.queryParamPrefix + 'q') in data) {
                this.search = data[this.queryParamPrefix + 'q'];
                this.parseFilters();
            } else {
                this.search = '';
            }

            if ((this.queryParamPrefix + 'page') in data) {
                this.current = ~~Number(data[this.queryParamPrefix + 'page']) || this.current;
            } else {
                this.current = 1;
            }

            if ((this.queryParamPrefix + 'limit') in data) {
                this.limit = ~~Number(data[this.queryParamPrefix + 'limit']) || this.limit;
                let lastPage = ~~Math.ceil((this.total ?? 0) / (this.limit ?? 1));
                this.current = this.total && this.current > lastPage ? lastPage : this.current;
            } else {
                this.limit = this.limitPerPage;
            }

            if ((this.queryParamPrefix + 'sort') in data) {
                try {
                    this.sorting = JSON.parse(data[this.queryParamPrefix + 'sort']);
                    this.parseSort();
                } catch (e) { }
            } else {
                this.sort = [];
                this.sorting = null;
            }

            this.updateRoute();
        });

        this.subscriptions.push(subscription);

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item && item.unsubscribe());
    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch(item.getType()) {
                case 'cell':
                    this.templateCell = item.template;
                    break;
            }
        });
    }

    onLimitChange(event: Event, limit: number) {
        event?.preventDefault();
        this.limit = limit;

        const lastPage = ~~Math.ceil((this.total ?? 0) / (this.limit ?? 1));
        this.current = this.total && this.current > lastPage ? lastPage : this.current;

        this.updateRoute();
    }

    onPageChange(page: number) {
        this.current = page;
        this.updateRoute();
    }

    onSearchEnter(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);
        this.current = 1;
        this.parseFilters();
        this.updateRoute();
    }

    onSearchDebounce(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);
        this.searchDebounce = setTimeout(() => {
            this.current = 1;
            this.parseFilters();
            this.updateRoute();
        }, this.searchDebounceTimeout || 500);
    }

    onClearSearch(event?: MouseEvent|Event): void {
        this.current = 1;
        this.search = '';
        this.filters = {};
        this.parseFilters();
        this.updateRoute();
    }

    onRowActionClick(event: Event, row: any, item: ListingTableComponentAction): boolean {
        event.preventDefault();

        if (item.confirm) {
            this.showConfirm((item?.confirm || ''), (item?.confirmTitle || '')).then(() => {
                this.onAction.emit({action: item?.action as string, data: row[this.uuid]});
            }).catch(() => { }).finally(() => this.confirmShow = false);
            return false;
        }
        this.onAction.emit({action: item?.action as string, data: row[this.uuid]});

        return false;
    }

    onActionClick(event: MouseEvent, action: string) {
        this.onAction.emit({action, data: {event}});
    }

    showConfirm(message: string, title?: string) {
        this.confirmMessage = message || null;
        this.confirmMessageTitle = title || null;
        this.confirmShow = true;
        return new Promise((resolve, reject) => {
            this.confirmCallbackResolve = resolve;
            this.confirmCallbackReject = reject;
        });
    }

    onConfirmAction(action: string): void {
        if (action === 'yes') {
            this.confirmCallbackResolve();
            return;
        }
        this.confirmCallbackReject();
    }


    onSortBy(event: Event, column: ListingTableComponentColumn, exactDirection?: string|any): boolean {
        event.preventDefault();

        const name = column.sortField;
        const index = this.sort.findIndex(item => item.sortField === name);
        let direction: ListingTableSortDirection = 'asc';

        if (!exactDirection && this.sort[index]?.direction === 'desc') {
            this.onSortRemove(event, column);
            return false;
        }

        if (index === -1) {
            direction = exactDirection || 'asc';
            this.sort.push({...column, field: name, direction});
        } else {
            direction = exactDirection || (this.sort[index].direction === 'asc' ? 'desc' : 'asc');
            this.sort[index].direction = direction;
        }

        column.direction = direction;

        this.updateRoute();
        return false;
    }

    onSortRemove(event: Event, column: any) {
        event.preventDefault();

        const index = this.sort.findIndex(item => item.sortField === column.sortField);
        if (index >= 0) {
            const field = this.sort[index].sortField;

            delete this.columns.find(column => column.sortField === field)?.direction;

            this.sort.splice(index, 1);
            this.updateRoute();
        }

        return false;
    }

    onSortRemoveAll(event?: Event): void {
        event?.preventDefault();
        this.sort = [];
        this.columns = this.columns.map(column => (delete column['direction'], column)).slice();
        this.updateRoute();
    }


    onFilterBy(event: any, column: ListingTableComponentColumn): boolean {
        this.current = 1;

        if (typeof event.value === 'object' && Array.isArray(event.value)) {
            let columnValue = event.value;
            columnValue = columnValue?.map((val: string) => ('' + (val || ''))?.trim()).filter((val: string) => val);
            column?.filter && (column.filter.value = columnValue?.length ? columnValue : null);
            this.filters[column?.filter?.field || ''] = columnValue?.length ? columnValue?.join(',') : null;
        } else {
            let columnValue = event.value;
            column?.filter && (column.filter.value = columnValue?.length ? columnValue : null);
            this.filters[column?.filter?.field || ''] = event.value;
        }

        this.updateRoute();
        return true;
    }

    onDateFilterClose(event: any, column: ListingTableComponentColumn): void {
        this.showModalDate = false;

        if (event.action !== 'apply' || typeof column?.filter?.value === 'undefined') {
            return;
        }

        let filter = null;
        column.filter.value = event?.data?.slice() || null;

        if (column?.filter?.value && Array.isArray(column?.filter?.value)) {
            column.filter.value[0] = moment(column?.filter?.value[0]).startOf('day').utc().toDate();
            column.filter.value[1] = column?.filter?.value[1]
                ? moment(column?.filter?.value[1]).endOf('day').utc().toDate()
                : moment(column?.filter?.value[0]).endOf('day').utc().toDate();

            filter = [
                ~~(column.filter.value[0].getTime() / 1000),
                ~~(column.filter.value[1].getTime() / 1000),
            ].join('-');
        }

        if ( column.field?.length && column.field in this.filters) {
            this.filters[column.field] = filter;
        }
        this.updateRoute();
    }

    onSortDragEnd() {
        this.updateRoute();
    }

    isFunction(any: any): boolean {
        return any && typeof any === 'function';
    }

    protected computeNoData() {
        this.noData = this.status !== 'loading' && (!this.total || !this.rows.length);

        if (!this.loading) {
            this.currentCount = ((this.current || 0) - 1)
                * (this.perPage || 1)
                + (this.innerRows?.length || 0)
            ;
        }
    }

    protected parseSort(): void {
        if (!this.sorting || !this.columns?.length) {
            return;
        }

        let sorting = [];
        for (let sortKey in this.sorting) {
            let column = this.columns.find(column => column.sortField === sortKey);
            if (!column) {
                continue;
            }

            column.direction = this.sorting[sortKey];
            sorting.push({...column, field: column.sortField, direction: column.direction});
        }

        this.sort = sorting.slice();
        this.sort?.length && this.updateRoute();
    }

    protected parseFilters() {
        let filters: ListingTableComponentFilterEvent = {};

        if (this.columns?.length) {
            this.columns.filter(column => column?.filter?.field?.length).forEach(column => {
                const type: ListingTableComponentColumnFilterType|null = column.filter?.type || null;
                let columnValue = column?.filter?.value;
                let filterValue = null;
                const filter: string|null = this.search?.split(' ').find(item => item && item.startsWith((column.filter?.field || '') + ':')) || null;

                if (!filter?.length) {
                    if ((column?.filter?.field || '') in filters) {
                        delete filters[column?.filter?.field || ''];
                    }
                    column.filter && (column.filter.value = null);
                    return;
                }

                if (type === 'list') {
                    columnValue = filter.split(':')[1]?.split(',') || columnValue;
                    filterValue = columnValue.join(',');
                } else if (type === 'dates') {
                    columnValue = filter.split(':')[1]?.split('-')?.map(item => moment((~~item) * 1000).toDate()) || columnValue;
                    filterValue = (
                        columnValue
                            .map((date: any) => date?.getTime() / 1000)
                            .filter((item: any) => item)
                            .map((item: any) => ~~item).join('-')
                    ) ?? null;
                }

                if (columnValue !== column?.filter?.value && column.filter) {
                    column.filter.value = columnValue;
                    filters[column.filter.field] = filterValue;
                }
            });

            this.filters = filters;
            this.columns = this.columns.slice();
            Object.keys(this.filters).length && this.updateRoute();
        }
    }

    protected computeLoading() {
        this.loading = (this.status?.length && ((this.status || '').toLowerCase() === 'loading')) as boolean;
    }
    protected computeHasError() {
        this.hasError = (this.status?.length && (this.status || '').toLowerCase() === 'error') as boolean;
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.sort, event.previousIndex, event.currentIndex);
        this.updateRoute();
    }

    protected updateRoute() {
        let sort = null;
        try {
            sort = (this.sort || [])
                .filter(i => i.field?.length && ['asc', 'desc'].indexOf(i?.direction || '') >= 0)
                .reduce((previous: any, current) => {
                    if (current?.field) {
                        previous[current?.field] = current.direction;
                    }
                    return previous;
                }, {});
            sort = JSON.stringify(sort) || null;
            sort = sort === '{}' ? null : sort;
        } catch (e) { }

        try {
            this.search = Object.keys(this.filters).reduce((previous: string, field: string) => {
                previous = previous.split(' ').filter(item => item && !item.startsWith(field + ':')).join(' ');
                let val = this.filters[field];

                if (val !== null && typeof val !== 'undefined') {
                    previous += ' ' + field + ':' + val + ' ';
                }

                return previous;
            }, this.search);
        } catch (e) {}

        this.router.navigate([], {queryParams: {
            ...this.route?.snapshot?.queryParams,
            [this.queryParamPrefix + 'q']: this.search?.trim() || null,
            [this.queryParamPrefix + 'sort']: sort,
            [this.queryParamPrefix + 'page']: (this.current > 1 ? this.current : 0) || null,
            [this.queryParamPrefix + 'limit']: (this.limit != this.limitPerPage ? this.limit : 0) || null,
        }}).then(() => {
            this.updateEvent && clearTimeout(this.updateEvent);
            this.updateEvent = setTimeout(() => {
                this.onUpdate.emit({
                    prefix: this.queryParamPrefix,
                    q: this.search?.trim() || '',
                    filters: this.filters,
                    sort: this.sort,
                    page: this.current,
                    limit: this.limit,
                });
            }, 100);
        });
    }

    onDropdownChange(event?: Event, visible: boolean = false): void {
        this.dropdownMenuTarget = visible ? (event?.target as HTMLElement || null) : this.dropdownMenuTarget;

        if (!visible && !this.dropdownMenuTarget?.isEqualNode(event?.target as HTMLElement)) {
            // Hack to prevent incorrect "visible" state when user clicked
            // from one to another dropdown without clicking anywhere else
            return;
        }

        this.dropdownMenuShow = visible;
    }

    toggleRow(event?: any, row: any = {}) {
        const allowElements = [
            'tr', 'td', 'tbody', 'thead', 'tfoot', 'th', 'span', 'div', 'label', 'em', 'i'
        ];

        if (allowElements.indexOf(event?.target?.tagName.toLowerCase()) >= 0) {
            event.preventDefault();
            event.stopPropagation();

            row.shown = !row.shown;
        };
    }
}
