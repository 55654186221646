<div class="row">
    <aside class="col-auto px-0" *ngIf="!isUserGuest && withSideMenu" [class.floating]="fullWidth">
        <app-sidemenu></app-sidemenu>
    </aside>

    <main class="mx-auto col-12" [ngClass]="{
        'col-md-10 col-lg ms-lg-3 ms-xxl-5': (!isUserGuest || forceShrinkWidth) && !fullWidth,
        'col-sm-7 col-md-6 col-xl-4 ': isUserGuest && !forceShrinkWidth && !fullWidth,
        'full-width-page': fullWidth
    }">
        <div class="row">
            <div class="col-12" [ngClass]="{
                'px-0': fullWidth,
                'col-xl-8': contentSidebar,
                'col-xl-10': contentCover,
                'col-xl-12': !contentSidebar && !contentCover
            }">
                <breadcrumb [breadcrumb]="breadcrumb" class="d-none d-lg-block" [class.mt-3]="breadcrumb.length > 0"></breadcrumb>

                <div [class.pt-3]="!fullWidth" #mainContent>
                    <div class="row mb-5 headline" *ngIf="headline?.length || contentHeadline">
                        <div  class="col-12" [ngClass]="{
                            'col-lg-8': headlineType === 'short' || headlineImage
                        }">
                            <div class="title">
                                <div *ngIf="!headlineImage && !contentActions">
                                    <h1 *ngIf="headline?.length">{{ headline }}</h1>
                                    <ng-container *ngTemplateOutlet="contentHeadline ?? null"></ng-container>
                                </div>

                                <div class="row" *ngIf="headlineImage">
                                    <div class="col d-flex align-items-center header-title">
                                        <img [src]="headlineImage" height="auto" alt="bubble" class="d-inline d-lg-none p-1 p-sm-2">
                                        <h1 *ngIf="headline?.length">{{ headline }}</h1>

                                        <ng-container *ngTemplateOutlet="contentHeadline ?? null"></ng-container>
                                    </div>

                                    <div class="col-auto d-flex align-items-center" *ngIf="contentActions">
                                        <ng-container *ngTemplateOutlet="contentActions"></ng-container>
                                    </div>
                                </div>

                                <div class="row" *ngIf="!headlineImage && contentActions">
                                    <div class="col">
                                        <h1 *ngIf="headline?.length">{{ headline }}</h1>
                                        <ng-container *ngTemplateOutlet="contentHeadline ?? null"></ng-container>
                                    </div>

                                    <div class="col-auto d-flex align-items-center" *ngIf="contentActions">
                                        <ng-container *ngTemplateOutlet="contentActions"></ng-container>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-4 d-none d-lg-flex justify-content-end headline-cover" *ngIf="headlineImage">
                            <img [src]="headlineImage" height="auto" alt="bubble">
                        </div>
                    </div>

                    <div class="content">
                        <ng-content ></ng-content>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-xxl-2 col-12 px-0 ms-auto" *ngIf="contentCover">
                <ng-container *ngTemplateOutlet="contentCover"></ng-container>
            </div>
        </div>
    </main>
</div>


