import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    protected breadcrumb: BehaviorSubject<MenuItem[]>|any = new BehaviorSubject([]);
    protected title: BehaviorSubject<String[]>|any = new BehaviorSubject([environment.application]);
    protected items: MenuItem[]|any = [];
    protected titlePrefix: string|null = null;
    protected showMobileNavigation: boolean = true;

    constructor() { }

    init(route: ActivatedRoute): void {
        this.items = [];
        this.items = this.createBreadcrumbs(route);
        this.breadcrumb.next(this.items.slice());
        this.title.next(this.getTitles());
    }

    getTitles(): String[] {
        return (this.items || []).map((i: any) => i.label);
    }

    changes(): Observable<MenuItem[]> {
        return this.breadcrumb.pipe(share());
    }

    replace(searchName: string | {[key: string]: string}, replaceWithTitle?: string): void {
        this.items = this.items.map((item: MenuItem) => {
            if (item.id && typeof searchName !== 'string') {
                item.label = item.id in searchName ? searchName[item.id] : item.label;
            } else if (item.id && typeof searchName === 'string' && item.id === searchName) {
                item.label = replaceWithTitle;
            }
            return item;
        });
        this.breadcrumb.next(this.items.slice());
        this.title.next(this.getTitles());
    }

    toggleMobileNavigation(show: boolean|null = null): void {
        this.showMobileNavigation = show === null ? !this.showMobileNavigation : show;
        let items: MenuItem[] = this.items
            .map((item: MenuItem) => Object.assign({}, item, {state: {skip: !this.showMobileNavigation}}));

        // avoid 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => {
            this.items = items.slice();
            this.breadcrumb.next(items.slice());
        });
    }

    setTitlePrefix(prefixLabel?: string): void {
        this.titlePrefix = prefixLabel?.length ? prefixLabel : null;
        this.title.next(this.getTitles());
    }

    getTitlePrefix(): string|null {
        return this.titlePrefix;
    }

    onTitleUpdate(): Observable<string[]> {
        return this.title.pipe(share());
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            breadcrumbs = breadcrumbs.filter((item, index, self) =>
                index === self.findIndex((found) => (
                    found.label === item.label && found.routerLink === item.routerLink
                ))
            );
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }

            const label = child.snapshot.data['breadcrumb'] || child.snapshot.data['title'] || null;
            const id = child.snapshot.data['name'] || null;
            if (label !== undefined && label !== null) {
                breadcrumbs.push({label, routerLink: url, id, state: {skip: false}});
            }

            return this.createBreadcrumbs(child, url, breadcrumbs);
        }

        return [];
    }
}
