import { CourseShort } from "./../course-short.model";
import { EventItem } from "./event-item.model";

export class Event {
    id?: number;
    title?: string;
    course?: CourseShort;
    events?: EventItem[];

    static fromJson(fields: any): Event {
        let item = Object.assign(new this(), fields);
        item.course = CourseShort.fromJson(fields?.course);
        item.events = fields?.events?.map((item: any) => EventItem.fromJson(item));
        return item;
    }
}
