import { FranchiseCourseSectionItem } from "./franchise-course-section-item.model";

export type FranchiseCourseSectionStatus = ('active'|'inactive');
export type FranchiseCourseSectionType = ('header'|'intro'|'faq'|'program'|'form'|'footer'|'advantages');

export class FranchiseCourseSection {
    id?: number;
    course?: number;
    title?: string;
    subtitle?: string;
    description?: string;
    thumbnail?: string;
    image?: string;
    alt?: string;
    type?: FranchiseCourseSectionType;
    status?: FranchiseCourseSectionStatus;
    position?: number;
    items?: FranchiseCourseSectionItem[];

    static fromJson(fields: any): FranchiseCourseSection {
        let item = Object.assign(new this(), fields);

        item.items = fields?.items?.map((item: any) => FranchiseCourseSectionItem.fromJson(item));
        return item;
    }
}
