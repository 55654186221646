import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiService } from './api.service';
import { UploadModel } from '../models/upload.model';
import { UploadBlogModel } from '../models/upload-blog.model';
import { HttpEventType } from '@angular/common/http';

export interface ImageDim {
    width: number,
    height: number
};

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    private readonly NODE_IMAGE_FETCH: string = '/image_store/image_to_base64';

    private readonly MAX_WIDTH: number = 1920;
    private readonly MAX_HEIGHT: number = 1920;

    constructor(private api: ApiService) { }

    /**
     * Upload an image file
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    image(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Observable<{data: UploadModel}> {
        return this.makeRequest('image', filename, file, chunks, chunk, onProgress);
    }


    /**
     * Upload a general-purpose file
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    file(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Observable<{data: UploadModel}> {
        return this.makeRequest('file', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a user-specific avatar
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    avatar(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Observable<{data: UploadModel}> {
        return this.makeRequest('avatar', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a user-specific avatar
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    blog(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Observable<{data: UploadBlogModel}> {
        return this.makeRequest('blog', filename, file, chunks, chunk, onProgress);
    }

    makeRequest(
        uri: string,
        filename: string,
        file: Blob,
        chunks?: number,
        chunk?: number,
        onProgress?: CallableFunction
    ): Observable<{data: UploadModel}> {
        let data = new FormData();
        data.append('file', file, filename);

        if (Number(chunks) >= 1 && Number(chunk) >= 0 ) {
            data.append('chunks', '' + Number(chunks));
            data.append('chunk', '' + Number(chunk));
        }

        return this.api.post('/uploads/' + uri, data, {
            headers: {headers:{
                'Content-Type': 'application/octet-stream'}
            },
            reportProgress: true,
            observe: 'events'
        }).pipe(map(event => {
            if (event.type === HttpEventType.Response) {
                return event.body;
            }
            if (event.type === HttpEventType.UploadProgress) {
                onProgress && onProgress(event);
            }
            return false;
        }), filter(item => item));
    }

    /**
     * Fetch Image as base64, by given URL address
     *
     * @see fetchImageBinaryFromURL() method
     * @param url
     */
    fetchImage(url: string, maxDim?: ImageDim): Promise<any> {
        return new Promise((resolve, reject) => {
            let image: HTMLImageElement = new Image();
            image.onerror = () => {
                reject({message: "Failed to get dimensions of an Image."});
            };
            image.onload = () => {
                let srcDim: ImageDim = {
                    width: image.width,
                    height: image.height,
                }

                this.fetchImageBinaryFromURL(url, srcDim, maxDim)
                    .subscribe(data => resolve(data), error => {
                        reject({message: "Failed to download image from server.", error});
                    });
            };
            image.src = url;
        });
    }

    /**
     * Fetch image as binary data by given URL address
     * This method is rquired, becuase:
     *   1. Returned image could be smaller on size
     *   2. Are from same host (avoid CORS issues)
     *   3. Does have specific "Access-Control-Allow-Origin: *" (again, avoid CORS issues)
     *
     * @param url URL of the image to be fetched, using server-side
     * @param srcDim Image' source dimensions
     * @param maxDim Maximum dimensions
     */
    fetchImageBinaryFromURL(url: string, srcDim: ImageDim, maxDim?: ImageDim): Observable<{data: UploadModel}> {
        return this.api.post('/uploads/url', {url, ...this.getOptionalDimensions(srcDim, maxDim)});
    }

    /**
     * Get optional dimmensions (on out of boundary) of an image
     * @param src
     * @param dest
     */
    getOptionalDimensions(src: ImageDim, dest?: ImageDim): ImageDim {
        let ratio: number = 1;
        dest = dest || {width: this.MAX_WIDTH, height: this.MAX_HEIGHT};

        if (src.width > dest.width || src.height > dest.height) {
            ratio = Math.min(dest.width / src.width, dest.height / src.height);
        }

        return {
            width: Math.round(src.width * ratio),
            height: Math.round(src.height * ratio),
        }
    }

    /**
     * Upload image as ArrayBuffer object
     * @param data Binary data of the image
     * @param filename Name of the file
     * @param mime MIME type of the file
     */
    uploadFromArrayBuffer(data: ArrayBuffer, filename: string = "Image.png", mime: string = "image/png"): Observable<any> {
        return this.image(filename, new Blob([data], {type: mime}), 2, 1);
    }
}
