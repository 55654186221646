<div class="rate" id="testimonialsStory">
    <div class="mobile-icon d-block d-xl-none m-auto">
        <img src="/assets/img/icons/star.svg" alt="">
    </div>
    <h3>
        <img src="/assets/img/icons/star.svg" alt="" class="d-none d-xl-inline">
        {{ 'Вашето мнение за нас' | transloco }}
        <div class="separator secondary d-flex align-items-center justify-content-xl-start justify-content-center">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <p class="mb-2">
        {{ 'Ще сме изключително благодарни, ако ни препоръчате във Facebook и Google. За да го направите, кликнете на иконките по-долу :)' | transloco }}
    </p>

    <div class="social d-flex justify-content-around">
        <a target="_blank" href="https://www.facebook.com/let.shakehands/reviews/?ref=page_internal">
            <img src="/assets/img/social/facebook.svg" alt="facebook">
        </a>
        <a target="_blank" href="https://www.google.com/search?q=advance+academy&oq=advance+academy&ie=UTF-8">
            <img src="/assets/img/social/google.svg" alt="google">
        </a>
    </div>
</div>
