export class FranchiseCourseDesign {
    id?: number;
    name?: string;
    title?: string;
    created?: string;
    updated?: string;

    static fromJson(fields: any): FranchiseCourseDesign {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
