import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'inline-text',
    templateUrl: './inline-text.component.html',
    styleUrls: ['./inline-text.component.scss'],
    host: {
        click: 'onClickComponent($event)',
    },
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InlineTextComponent),
        multi: true
    }]
})
export class InlineTextComponent implements OnInit {

    private onChange: (value: string) => void = (value: string) => {};
    private onTouched: () => void = () => {};

    fullView: boolean = false;
    isFirefox: boolean = false;
    touched = false;
    value: string = '';
    disabled: boolean = false;
    mode: ('edit'|'view') = 'view';

    @ViewChild('input') input: ElementRef|null = null;

    @Output() save: EventEmitter<string|null> = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();

    @Input() emptyLabel: string = translate('[неналичен текст]');
    @Input() min: number = 0;
    @Input() max: number = 0;
    @Input() rows: number = 2;
    @Input() placeholder: string|null = '';

    constructor() { }

    ngOnInit(): void {
        // Sniffing browser for the -webkit-line-clamp's text overlapping work-around
        this.isFirefox = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase().indexOf('firefox') > -1 : this.isFirefox;
    }

    onFullViewToggle(event: Event): boolean {
        event.preventDefault();
        event.stopPropagation();

        this.fullView = !this.fullView;

        return false;
    }

    onEdit(event: Event): boolean {
        event.preventDefault();

        if (this.disabled) {
            return false;
        }

        event.stopPropagation();

        this.mode = 'edit';

        setTimeout(() => {
            this.input && this.input.nativeElement.focus();
            this.edit.emit();
        }, 10);

        return false;
    }

    onSave(event: Event): boolean {
        event.preventDefault();
        event.stopPropagation();

        this.mode = 'view';
        this.onChange(this.value ?? '');
        this.save.emit(this.value ?? '');

        return false;
    }

    onCancel(event: Event): boolean {
        event.preventDefault();
        event.stopPropagation();

        this.mode = 'view';
        this.cancel.emit();

        return false;
    }

    onClickComponent(event: Event): boolean {
        event && event.stopPropagation();
        return false;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
