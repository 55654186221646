export class TheoryExample {
    id?: number;
    keyword?: string;
    description?: string;
    image?: string;

    static fromJson(fields: any): TheoryExample {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
