import { Course } from "./course.model";

export class GroupShort {
    id?: number;
    title?: string;
    date_start?: string;
    date_to?: string;
    course?: Course;

    static fromJson(fields: any): GroupShort {
        let item = Object.assign(new this(), fields);
        item.course = Course.fromJson(fields?.course);
        return item;
    }
}
