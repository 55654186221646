export class UserExam {
    id?: number;
    result?: number;
    type?: number;
    exam?: number;
    created_at?: string;
    expired_at?: string;
    finished_at?: string;
    current?: string;
    is_expired?: string;


    static fromJson(fields: any): UserExam {
        let item = Object.assign(new this(), fields);
        return item;
    }

}
