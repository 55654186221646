export class GroupModuleVideo {
    id?: number;
    video?: number;
    description?: string;
    status?: string;
    created?: string;
    update?: string;

    static fromJson(fields: any): GroupModuleVideo {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
