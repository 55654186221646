import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'payment-chooser',
    templateUrl: './payment-chooser.component.html',
    styleUrls: ['./payment-chooser.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaymentChooserComponent),
            multi: true,
        },
    ],
})

export class PaymentChooserComponent implements ControlValueAccessor {
    id?: string = Math.random().toString(36).substr(2, 10);
    @Input() items?: { value: any; image: string; icon: string, title: string }[] = [
        { value: 'cash', image: '', icon: 'icon-cash', title: translate('В брой') },
        { value: 'transfer', image: '', icon: 'fa-credit-card', title: translate('С карта') },
        { value: 'paypal', image: '', icon: 'fa-brands fa-paypal', title: translate('Чрез PayPal') },
        { value: 'bank', image: '', icon: 'fa-building-columns', title: translate('Банков трансфер') },
    ];
    @Input() name: string = '';
    @Input() selectedValue: any = null;
    @Input() errors: any = {};
    @Input() label: string = '';

    onChange: (value: any) => void = () => {};
    onTouched: () => void = () => {};

    onItemClick(item: { value: any; image: string; icon: string }) {
        this.selectedValue = item.value;
        this.onChange(item.value);
        this.onTouched();
    }

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      // Optionally, implement this if you need to handle disabled state
    }
}
