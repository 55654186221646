export type EventItemType = ('program'|'holiday');

export class EventItem {
    id?: number;
    start?: string;
    end?: string;
    title?: string;
    type?: EventItemType;

    static fromJson(fields: any): EventItem {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
