import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './shared/models/user/user.model';
import { AuthService } from './shared/services/auth.service';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { filter } from 'rxjs/operators';
import { I18nService } from './shared/services/i18n.service';
import { DomService } from './shared/services/dom.service';
import { RuntimeService } from './shared/services/runtime.service';
import { DOCUMENT } from '@angular/common';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    readonly BASE_URL: string = environment.scheme + '://' + environment.domain;

    protected subscriptions: Subscription[] = [];
    protected innerUser: User|null = null;

    private page: string|null = null;

    breadcrumbNavigation: MenuItem[] = [];
    breadcrumbNavigationLast: MenuItem|null = null;
    title: string = environment.application ?? '';
    isUserGuest: boolean = true;

    showContents: boolean = false;
    showContentsTimer: any = null;
    showInstructions: boolean = false;
    showRefreshAskModal: boolean = false;

    fullWidth: boolean = false;
    loading: boolean = true;



    set user(value: User|null) {
        if (this.innerUser !== value) {
            this.innerUser = value;
            this.isUserGuest = !(this.innerUser?.id ?? 0);
        }
    }
    get user(): User|null {
        return this.innerUser;
    }

    constructor(
        @Inject(DOCUMENT) private document: any,
        private runtimeService: RuntimeService,
        private i18nService: I18nService,
        private titleService: Title,
        private metaService: Meta,
        private authService: AuthService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private domService: DomService,
        private renderer: Renderer2,
    ) {
        this.titleService.setTitle(this.title);
    }

    ngOnInit(): void {
        this.user = this.authService.getUser();

        this.i18nService.init();

        const authSubscription = this.authService.getAuthReady().subscribe(status => {
            this.loading = !status;
            this.user = this.authService.getUser();
        })
        this.subscriptions.push(authSubscription);

        const breadcrumbSubscribe = this.breadcrumbService.changes().subscribe((data: any) => {
            this.breadcrumbNavigation = data;
            setTimeout(() => this.breadcrumbNavigationLast = data.slice(-2, -1)[0] || null);
        });
        this.subscriptions.push(breadcrumbSubscribe);

        const breadcrumbTitleSubscribe = this.breadcrumbService.onTitleUpdate().subscribe(title => {
            const newTitle = [title?.map(item => translate(item)).join(' - '), this.title].filter(item => item?.trim()?.length).join(' | ');
            this.titleService.setTitle(newTitle);
            this.metaService.updateTag({
                property: 'og:title',
                content: newTitle
            });
        });
        this.subscriptions.push(breadcrumbTitleSubscribe);

        this.router.events
            .pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe(() => {
                const routeState = this.router.getCurrentNavigation()?.extras?.state ?? null;

                setTimeout(() => {
                    if (routeState && "skipMoveTop" in routeState && routeState['skipMoveTop']) {
                        return;
                    }

                    this.domService.moveToTop();
                }, 0);

                let current = this.route.root;
                while (current.children[0] !== undefined) {
                    current = current.children[0];
                }

                this.fullWidth = current.snapshot.data['fullWidth'] || false;

                this.page = current.snapshot.data['page'] || null;

                // @todo: Using times for toggle the showContents variable will bring back horizontal scroll on first page loading
                // However, these timers are related somehow to tabs/accordion handlers for mobile/desktop switching...
                if (this.page && (this.page === 'live' || this.page === 'landing' || this.page === 'courses/dashboard' || this.page === 'courses/contact')) {
                    this.showContents = false;
                    // this.showContentsTimer && clearTimeout(this.showContentsTimer);
                    // this.showContentsTimer = setTimeout(() => { this.showContents = false; });
                } else {
                    this.showContents = true;
                    // this.showContentsTimer && clearTimeout(this.showContentsTimer);
                    // this.showContentsTimer = setTimeout(() => { this.showContents = true; });
                    this.breadcrumbService.init(this.route.root);
                }
            });

        const i18nSubscribe = this.i18nService.onLocaleChanged().subscribe(locale => {
            this.renderer.setAttribute(this.document?.querySelector("html"), 'lang', locale.language);
            this.metaService.updateTag({
                property: 'og:locale',
                content: locale.locale
            });
        });
        this.subscriptions.push(i18nSubscribe);


        const refreshSubscribe = this.runtimeService.askRefresh().subscribe(next => {
            this.showRefreshAskModal = next;
        });
        this.subscriptions.push(refreshSubscribe);

    }

    ngOnDestroy(): void {
        this.subscriptions.map(subscription => subscription?.unsubscribe());
    }

    moveOnTop(): void {
        if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
            document.documentElement.scrollTop = 0;
        }

        // Safari (and older Chrome) fallback
        if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
            document.body.scrollTop = 0;
        }
    }

    onModalRefresh(event?: string): void {
        this.showRefreshAskModal = false;

        if (event !== 'yes') {
            return;
        }

        setTimeout(() => {
            window?.location?.reload();
        }, 150);
    }
}
