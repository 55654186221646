export type CourseDesignNestedStatus = ('active'|'inactive');

export class CourseDesignNested {
    id?: number;
    name?: string;
    design?: string;
    url?: string;
    position?: number;
    status?: CourseDesignNestedStatus;
    children?: CourseDesignNested[];

    static fromJson(fields?: any): CourseDesignNested {
        let item = Object.assign(new this(), fields);
        item.children = item?.children?.map((item: any) => CourseDesignNested.fromJson(item))
        return item;
    }
}
