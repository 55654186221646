export class FranchisePlanFeatures {
    id?: number;
    name?: string;
    value?: string;
    type?: boolean;
    title?: string;
    description?: string;

    static fromJson(fields: any): FranchisePlanFeatures {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
