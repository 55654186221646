<div class="payments mb-4 d-flex flex-column">
    <h3>
        {{ headline ?? ('Предстоящо плащане' | transloco) }}
        <div class="separator third d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <loading [loading]="loading" [hasData]="!!paymentsData?.data"></loading>

    <ng-container *ngIf="paymentsData?.data?.length !== 0">
        <ng-container *ngFor="let payment of paymentsData?.data">
            <card-payment class="d-block mb-3"
                          [payment]="payment"
                          [withName]="studentsList"
                          (onClick)="onShowPayment($event, payment)"
            ></card-payment>
        </ng-container>
    </ng-container>

    <p *ngIf="paymentsData?.data?.length === 0 && !loading" class="my-4">
        {{ noResult ?? ('Нямате предстоящи плащания' | transloco) }}
    </p>

    <a [routerLink]="allPaymentsUrl ? allPaymentsUrl : '/payments'" [queryParams]="allPaymentsUrlParams" class="primary-btn mb-5" *ngIf="!loading">
        {{ 'Виж всички плащания' | transloco }}
    </a>
</div>

<modal-payment *ngIf="showPayment"
                (onAction)="onPaymentAction($event)"
                [amount]="payment?.price?.eur || 0"
                [title]="payment?.group?.course?.title"
                [paymentId]="payment?.id || 0"
                [description]="payment?.description ?? ''"
></modal-payment>
