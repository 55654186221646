import { ProjectMessageUser } from "./project-message-user.model";

export class ProjectMessage {
    id?: number;
    sender?: ProjectMessageUser;
    recipient?: number;
    content?: string;
    created?: Date;

    static fromJson(fields: any): ProjectMessage {
        let item = Object.assign(new this(), fields);
        item.sender = ProjectMessageUser.fromJson(fields?.sender);
        item.created = new Date(fields.created);
        return item;
    }
}
