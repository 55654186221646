<div class="card text-center h-100">
    <a class="card-body" [routerLink]="'/groups/' + group?.id">
        <div class="row">
            <div class="col-auto d-flex flex-column align-items-center">
                <avatar *ngIf="group?.course?.logo?.length" [photo]="group?.course?.logo ?? ''" size="small"></avatar>

                <span class="badge rounded-pill text-bg-danger mt-2" [class.invisible]="group?.status === 'active'">{{ 'Неактивен' | transloco }}</span>
            </div>

            <div class="col">
                <div class="row">
                    <div class="col-12">
                        <h5 class="card-title text-start mb-1">
                            {{ group?.course?.title ?? 'Курс' }}
                        </h5>
                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div class="col d-flex flex-column text-start card-info-text-wrapper">
                                <p class="card-text mb-0">
                                    {{ group?.title ?? 'Група' }}
                                </p>

                                <p class="dates mb-0" *ngIf="group?.parsed_date_start || group?.parsed_date_to" [class.text-muted]="group?.expired">
                                    <span class="badge text-bg-secondary align-middle" *ngIf="group?.expired">{{ 'Минал курс' | transloco }}</span>
                                    {{ group?.parsed_date_start | dateLocale:'dd.MM.yy' }} - {{ group?.parsed_date_to | dateLocale:'dd.MM.yy' }}
                                </p>
                            </div>

                            <div class="col d-flex justify-content-end align-items-center">
                                <div class="p-2 my-auto" [title]="'Преглед' | transloco">
                                    <i class="icons-p2 fas fa-arrow-circle-right fa-fw fa-lg"></i>
                                </div>

                                <a  class="p-2 my-auto"
                                    [title]="'Редактиране' | transloco"
                                    [routerLink]="'/groups/' + group?.id + '/edit'"
                                    *ngIf="withEdit && user && (user | roleAny: 'admin':'partner')"
                                >
                                    <i class="icons-p2 fa-solid fa-pen-to-square fa-fw fa-lg"></i>
                                </a>
                                <a class="p-2 my-auto"
                                    [title]="'Премахване' | transloco"
                                    (click)="onShowConfirm($event)"
                                    *ngIf="withRemove && user && (user | roleAny: 'admin':'partner')"
                                >
                                    <i class="icons-p2 fa-regular fa-trash-can fa-fw text-danger fa-lg"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>

<modal-confirm *ngIf="showConfirm"
               [body]="'Желаете ли да премахнете тази група?' | transloco"
               [headline]="'Премахване на група?' | transloco"
               [skippable]="true"
               (onAction)="onDeleteConfirmAction($event)"
></modal-confirm>
