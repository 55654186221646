import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    transform(value?: Date, ...args: string[]): Date|string {
        let time: any = moment(value);

        if (args.length) {
            return args[0] in time && typeof time[args[0]] === 'function'
                ? time[args[0]].apply(time, args.slice(1))
                : time.format(args[0]).toString();
        }
        return time.toDate();
    }
}
