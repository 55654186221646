import { Zoom } from "./zoom.model";

export class Live {
    online?: boolean;
    zoom?: Zoom;
    google?: string;

    static fromJson(fields: any): Live {
        let item = Object.assign(new this(), fields);
        item.zoom = fields?.zoom && Zoom.fromJson(fields?.zoom);
        return item;
    }
}
