import { UserRoleName } from './user-role.model';
import { User } from './user.model';

export class UserAuth {
    user: User = User.fromJson({});
    token: string = '';

    /**
     * Check if User data is invalid
     */
    isValid(): boolean {
        return this.hasToken()
            && (this.user?.name || '').length > 0
            && (this.user?.id || 0) > 0;
    }

    /**
     * Check if stored token is available
     */
    hasToken(): boolean {
        return (this.token || '').length > 0;
    }

    /**
     * Get current stored token
     */
    getToken(): string {
        return (this.token || '').length ? this.token : '';
    }

    /**
     * Set user token
     */
    setToken(token: string): this {
        if (token && typeof token === 'string' && token.length > 0) {
            this.token = token;
        }

        return this;
    }

    /**
     * Determine if user is one of the roles in the listing
     * @param roles
     * @returns
     */
    can(roles: UserRoleName[]): boolean {
        return this.user?.can(roles);
    }

    static fromJson(fields: any): UserAuth {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(item.user);
        return item;
    }
}
