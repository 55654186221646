import { CourseChildDesign } from "./course-child-design.model";

export class CourseDesignExtend {
    id?: number;
    design?: string;
    name?: string;
    child?: CourseChildDesign[];

    static fromJson(fields: any): CourseDesignExtend {
        let item = Object.assign(new this(), fields);
        item.child = fields?.child?.map((item: CourseChildDesign) => CourseChildDesign.fromJson(item));
        return item;
    }
}
