import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Paginate } from '../models/paginate.model';
import { Franchise } from '../models/franchise.model';
import { map, Observable } from 'rxjs';
import { FranchiseCourse } from '../models/franchise-course.model';
import { FranchiseCourseDesign } from '../models/franchise-course-design.model';
import { FranchiseCoursePublic } from '../models/franchise-course-public.model';
import { FranchiseQuery } from '../models/franchise-query.model';
import { FilterSort } from '../models/filter-sort.model';
import { FranchisePlan } from '../models/franchise-plan.model';
import { CourseDesignExtend } from '../models/course-design-extend.model';
import { Contact } from '../models/contact.model';
import { environment } from 'src/environments/environment';

export type FranchiseServiceSortType = (
    'id'
    | 'url'
    | 'title'
    | 'description'
    | 'program'
    | 'status'
    | 'weeks'
    | 'position'
);

export type FranchiseServiceFilter = {
    limit?: number;
    page?: number;
    expiration?: ('all'|'expired'|'active');
};
export type FranchiseServicePlanFilter = {
    limit?: number;
    page?: number;
    status?: ('all'|'expired'|'active');
};

export interface FranchiseServiceIndex  {
    limit?: number;
    page?: number;
    sort?: FranchiseServiceSortType|FilterSort<FranchiseServiceSortType>[],
    q?: string;
    status?: ('active'|'inactive');
};

export interface FranchiseServiceInputFranchise {
    administrator?: number;
    administrator_user?: {
        email?: string;
        password?: string;
        name?: string;
        surname?: string;
        lastname?: string;
        phone?: string;
        image?: string;
        status?: string;
    };
    plan?: number;
    logo?: string;
    url?: string;
    town?: number;
    name: string;
    email?: string;
    phone?: string;
    address?: string;
    homepage?: string;
    location_url?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;
    status?: ( "active" | "inactive" );
};

export interface FranchiseServiceInputFranchisePreference {
    type?: ( "integer" | "string" | "boolean");
    visibility?: ( "public" | "private" );
    name: string;
    value: string;
};

export interface FranchiseServiceInputUpdateFranchise {
    administrator?: number;
    plan?: number;
    logo?: string;
    url?: string;
    town?: number;
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    homepage?: string;
    location_url?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;
    status?: ( "active" | "inactive" );
    preferences?: FranchiseServiceInputFranchisePreference[];
};

export interface FranchiseServiceVideoUpload {
    file?: string;
    filename?: string;
    mime?: string;
    size?: number;
};
export interface FranchiseCourseRegistrationService  {
    age?: number;
    country?: string;
    description?: string;
    email?: string;
    grade?: string;
    name?: string;
    phone?: string;
    recaptcha?: string;
    videos?: FranchiseServiceVideoUpload[];
};

export interface FranchiseServiceIndexAvailable  {
    franchise?: number;
    status?: ('active'|'inactive');
    design?: ('kids'|'computer-science'|'web-development');
};


@Injectable({
    providedIn: 'root'
})

export class FranchiseService {

    constructor(
        protected api: ApiService,
    ) { }

    add(data: FranchiseServiceInputFranchise): Observable<{data: Franchise}> {
        return this.api.post('/franchises', data).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    delete(id: number): Observable<{ data: any }> {
        return this.api.delete('/franchises/' + id);
    }

    /**
     * Get list of franchises
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getList(filter?: FranchiseServiceFilter, maxCacheTime?: number): Observable<Paginate<Franchise>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/franchises', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Franchise>(), data);
                data.data = data.data?.map((item: any)=> Franchise.fromJson(item));
                return data;
            })
        )
    }


    /**
     * Get list of plans
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getPlansList(filter?: FranchiseServicePlanFilter, maxCacheTime?: number): Observable<Paginate<FranchisePlan>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/plans', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<FranchisePlan>(), data);
                data.data = data.data?.map((item: any)=> FranchisePlan.fromJson(item));
                return data;
            })
        )
    }

    getFranchise(id: number): Observable<{data: Franchise}> {
        return this.api.get('/franchises/' + id).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    updateFranchise(id: number, data: FranchiseServiceInputUpdateFranchise): Observable<{data: Franchise}> {
        return this.api.post('/franchises/' + id, data).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    queryCompanyDetails(taxNumber: string|number): Observable<{data: FranchiseQuery}> {
        return this.api.get('/franchises/query/' + taxNumber).pipe(
            map(data => {
                data.data = FranchiseQuery.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get Franchise Courses List
     * @param id
     * @param filter
     * @returns
     */
    getCoursesList(id: number, filter?: FranchiseServiceIndex): Observable<Paginate<FranchiseCourse>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as FranchiseServiceSortType);

        return this.api.get('/franchises/' + id + '/courses', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<FranchiseCourse>(), data);
                data.data = data.data?.map((item: any)=> FranchiseCourse.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Add new Course to Franchise
     * @param id
     * @param data
     * @returns
     */
    storeCourse(id: number, data: any): Observable<{data: FranchiseCourse}> {
        return this.api.post('/franchises/' + id + '/courses', data).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get a Franchise Course
     * @param id
     * @param courseId
     * @returns
     */
    getCourse(id: number, courseId: number): Observable<{data: FranchiseCourse}> {
        return this.api.get('/franchises/' + id + '/courses/' + courseId).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update existing Franchise Course
     * @param id
     * @param courseId
     * @param data
     * @returns
     */
    updateCourse(id: number, courseId: number, data: any): Observable<{data: FranchiseCourse}> {
        return this.api.post('/franchises/' + id + '/courses/' + courseId, data).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete a Course from Franchise
     * @param id
     * @param courseId
     * @returns
     */
    deleteCourse(id: number, courseId: number): Observable<{data: any}> {
        return this.api.delete('/franchises/' + id + '/courses/' + courseId);
    }

    /**
     * Get Designs list for courses
     * @param id
     * @param data
     * @returns
     */
    getCourseDesigns(): Observable<{ data: FranchiseCourseDesign[] }> {
        return this.api.get('/franchises/courses/designs').pipe(
          map(response => {
            const designs = (response.data as FranchiseCourseDesign[]).map(item => FranchiseCourseDesign.fromJson(item));
            return { data: designs };
          })
        );
    }


    /**
     * Get a Course from Franchise by URL
     *
     * @param id
     * @param courseId
     * @returns
     */
    getCourseByUrl(id: number, url: string): Observable<{data: FranchiseCoursePublic}> {
        return this.api.get('/franchises/' + id + '/courses/url/' + url).pipe(
            map(data => {
                data.data = FranchiseCoursePublic.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get a Franchise by URL
     * @param url
     * @returns
     */
    getByUrl(url: string): Observable<{data: Franchise}> {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url;
        }

        const hostname = new URL(url).hostname;
        const isAddressLocal = /^(localhost|192\.168\.\d{1,3}\.\d{1,3}|10\.\d{1,3}\.\d{1,3}\.\d{1,3}|127\.\d{1,3}\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3})$/.test(hostname);
        const franchiseUrl = isAddressLocal ? environment?.domain : hostname;

        return this.api.get('/franchises/url/' + franchiseUrl).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get available courses (for purchase) by partner
     *
     * @param filter
     * @returns
     */
    getAvailableCourses(id: number, filter?: FranchiseServiceIndexAvailable): Observable<{data: CourseDesignExtend[]}> {
        return this.api.get('/franchises/' + id + '/courses/available', filter).pipe(
            map(response => {
                response.data = response.data?.map((item: CourseDesignExtend) => CourseDesignExtend.fromJson(item));
                return response;
            })
        );
    }

    franchiseVideoUpload(id: number, courseId: number, data: FranchiseCourseRegistrationService): Observable<any> {
        return this.api.post('/franchises/' + id + '/courses/' + courseId + '/enroll', data);
    }


    /**
     * Post franchise contact form submission
     *
     * @param id
     * @returns
     */
     storeContact(id: number, data: any): Observable<any> {
        return this.api.post(`/franchises/${id}/contact`, data).pipe(
            map(response => {
                response.data = response.data ? Contact.fromJson(response.data) : null;
                return response;
            })
        );

    }

}

