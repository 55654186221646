
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST} from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';

import { environment } from 'src/environments/environment';

interface CookieValue {
    name: string,
    value: string
};

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    public readonly DOMAIN = environment.production || typeof window === 'undefined'
        ? environment.domain
        : window?.location?.hostname;

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        @Optional() @Inject(REQUEST) private request: Request,
    ) {

    }

    get(name: string) {
        let cookiesFound: Array<string> = this.getCookies();
        let total: number = cookiesFound.length;
        let cookieName = `${name}=`;

        for (let i: number = 0; i < total; i++) {
            let item = cookiesFound[i].replace(/^\s+/g, '');
            if (item.indexOf(cookieName) == 0) {
                return item.substring(cookieName.length, item.length);
            }
        }
        return '';
    }

    delete(name: string): void {
        this.set(name, '', -1, '/');
    }

    set(name: string, value: string|number, expireDays: number|null = 1, path: string = '', domain: string = '') {
        let expires: string = '';

        if (expireDays !== null) {
            let d:Date = new Date();
            d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
            expires = `expires=${expireDays >= 0 ? d.toUTCString() : 'Sat, 01 Jan 2000 00:00:01 GMT'}`;
        }

        let cdomain: string = domain ? `; domain=${domain}` : '';
        let cpath: string = path ? `; path=${path}` : '';
        typeof document !== 'undefined' && 'cookie' in document && (document.cookie = `${name}=${value}; ${expires}${cdomain}${cpath}`);
    }

    getDomain(url: string = this.DOMAIN): string {
        //  if it is a "localhost", then should not be set; IE11 issue
        return url !== "localhost" ? '.' + url : "";
    }

    getAll(): CookieValue[] {
        let cookies: CookieValue[] = [];
        let cookiesFound: Array<string> = this.getCookies();
        let total: number = cookiesFound.length;

        for (let i: number = 0; i < total; i++) {
            let item = cookiesFound[i].replace(/^\s+/g, '');
            let [name, value] = item.split('=');
            cookies.push({
                name,
                value,
            });
        }

        return cookies;
    }

    /**
     * @note Keep constants for easy debugging!
     */
    protected getCookies(): string[] {
        const isServer: boolean = isPlatformServer(this.platform);
        const cookies: string = isServer
            ? (this.request?.headers?.cookie ?? '')
            : typeof document !== 'undefined' && 'cookie' in document
                ? document['cookie']
                : '';
        return (cookies || '').split(';');
    }
}
