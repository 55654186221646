export type CourseTopicStatus = ('active'|'inactive');

export class CourseTopic {
    id?: number;
    title?: string;
    hours?: number;
    status?: CourseTopicStatus;
    position?: number;

    static fromJson(fields: any): CourseTopic {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
