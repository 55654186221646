import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneLink'
})
export class PhoneLink implements PipeTransform {

    transform(phone?: string): string {
        if (!phone) {
            return '';
        }

        return (phone ?? '').startsWith('http') ? phone?.trim() : 'tel:' + phone?.trim();
    }
}
