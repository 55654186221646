<div class="d-flex flex-column flex-shrink-0 d-none d-md-block d-lg-block d-xl-block side-nav" style="width: 4.5rem;" >
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">

        <li class="nav-item" *ngFor="let menu of navigation">
            <a href="#"
                #menuItem
                class="nav-link py-3"
                [routerLink]="menu.url"
                routerLinkActive="active"
                [routerLinkActiveOptions]="menu.options || {exact: false}"
                [title]="menu.title"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                (click)="onNavItemLeave($event, menuItem)"
            >
                <i [class]="menu.icon"></i>
            </a>
        </li>

        <li class="bottom-side-nav mt-5">
            <a href="https://t.me/+86ohSNPnHEY0MDEy"
                class="nav-link py-2 mt-3 side-social-icon"
                [title]="'Telegram'"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                target="_blank"
            >

                <i class="fa-brands fa-telegram fa-fw"></i>
            </a>
        </li>
        <li class="mb-2">
            <a href="https://www.facebook.com/let.shakehands/"
                class="nav-link py-2 side-social-icon"
                [title]="'Посетете Facebook страницата ни' | transloco"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                target="_blank"
            >
                <i class="fa-brands fa-facebook-f fill-icon"></i>
            </a>
        </li>
    </ul>
</div>
