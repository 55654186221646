import { Inject, Injectable, LOCALE_ID, Renderer2 } from '@angular/core';
import { LangDefinition, TranslocoService, translate } from '@ngneat/transloco';
import { getBrowserLang } from '@ngneat/transloco';
import * as moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';
import { BehaviorSubject, Observable, share } from 'rxjs';

export interface I18nServiceLocale {
    locale: string;
    locale_code: string;
    locale_posix: string;
    language: string;
    country: string;
};

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    protected readonly DEBUG: boolean = !environment.production;
    protected readonly LOCALE_COOKIE_NAME: string = 'locale';

    protected subjectTranslationReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected subjectLanguage: BehaviorSubject<I18nServiceLocale> = new BehaviorSubject<I18nServiceLocale>({
        locale: '',
        locale_code: '',
        locale_posix: '',
        language: '',
        country: '',
    });

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private translocoService: TranslocoService,
        private cookieService: CookieService,
        private primeConfig: PrimeNGConfig,
    ) { }

    init() {
        this.initTransloco();
        this.initMoment(this.locale);
    }

    initPrimeNG() {
        // Setup i18n support for PrimeNG (globally)
        this.primeConfig.setTranslation({
            dayNames: [
                translate("weekday.sunday"),
                translate("weekday.monday"),
                translate("weekday.tuesday"),
                translate("weekday.wednesday"),
                translate("weekday.thursday"),
                translate("weekday.friday"),
                translate("weekday.saturday"),
            ],
            dayNamesShort: [
                translate("weekday.short.sunday"),
                translate("weekday.short.monday"),
                translate("weekday.short.tuesday"),
                translate("weekday.short.wednesday"),
                translate("weekday.short.thursday"),
                translate("weekday.short.friday"),
                translate("weekday.short.saturday"),
            ],
            dayNamesMin: [
               translate("weekday.shortest.sunday"),
               translate("weekday.shortest.monday"),
               translate("weekday.shortest.tuesday"),
               translate("weekday.shortest.wednesday"),
               translate("weekday.shortest.thursday"),
               translate("weekday.shortest.friday"),
               translate("weekday.shortest.saturday"),
            ],
            monthNames: [
                translate("month.january"),
                translate("month.february"),
                translate("month.march"),
                translate("month.april"),
                translate("month.may"),
                translate("month.june"),
                translate("month.july"),
                translate("month.august"),
                translate("month.september"),
                translate("month.october"),
                translate("month.november"),
                translate("month.december"),
            ],
            monthNamesShort: [
                translate("month.short.january"),
                translate("month.short.february"),
                translate("month.short.march"),
                translate("month.short.april"),
                translate("month.short.may"),
                translate("month.short.june"),
                translate("month.short.july"),
                translate("month.short.august"),
                translate("month.short.september"),
                translate("month.short.october"),
                translate("month.short.november"),
                translate("month.short.december"),
            ],
            today: translate("Днес"),
            clear: translate("Изчисти"),
            weekHeader: "С",
        });
    }

    initMoment(locale: string) {
        moment.locale(locale);
    }

    initTransloco() {
        const savedLocale = this.cookieService.get(this.LOCALE_COOKIE_NAME) || null;
        const locale: string = savedLocale ?? getBrowserLang() ?? this.translocoService.getDefaultLang();
        this.changeLanguage(locale);
    }

    /**
     * Returns status of the loaded translation
     */
    getTranslationLoadedReady(): Observable<boolean> {
        return this.subjectTranslationReady.asObservable().pipe(share());
    }

    availableLocales(): LangDefinition[] {
        const result =  this.translocoService.getAvailableLangs();

        return result.map(item => {
            return typeof item !== 'string' ? item : {
                id: item,
                label: item.toUpperCase()
            } as LangDefinition
        });
    }

    availableLocalesCodes(): string[] {
        return this.translocoService.getAvailableLangs().map(item => typeof item === 'string' ? item : item.id) ?? [];
    }

    activeLocale(): string {
        return this.translocoService.getActiveLang();
    }

    changeLanguage(languageName: string): void {
        const supported: string[] = this.availableLocalesCodes();
        const activeLocale = this.activeLocale();
        const locale = supported.indexOf(languageName) >= 0 ? languageName
            : (activeLocale ? activeLocale : this.translocoService.getDefaultLang());

        this.cookieService.set(this.LOCALE_COOKIE_NAME, locale, 365, '/')
        this.translocoService.setActiveLang(locale);

        this.translocoService.load(locale).subscribe({
            next: data => {
                this.translocoService.setActiveLang(locale);

                const localeDetails = this.getLocaleDetails();
                this.initMoment(localeDetails.locale_code);
                this.initPrimeNG();

                this.subjectLanguage.next(localeDetails);
                this.subjectTranslationReady.next(true);
            }, error: error => {
                this.DEBUG && console.log("Failed to load locale", locale, "Details:",  error);

                this.subjectTranslationReady.next(true);
            }
        });
    }

    getLocaleDetails(locale: string|null = null): I18nServiceLocale {
        locale = locale || this.activeLocale();
        let parts = locale.split(/[_-]/);
        parts = [
            (parts[0] ?? '').toLowerCase(),
            (parts[1] ?? parts[0] ?? '').toUpperCase(),
        ];

        return {
            locale: locale, // original
            locale_code: parts.join('-'),
            locale_posix: parts.join('_'),
            language: parts[0] ?? locale,
            country: parts[1] ?? '',
        };
    }

    /**
     * When a locale is changed
     * @returns
     */
    onLocaleChanged(): Observable<I18nServiceLocale> {
        return this.subjectLanguage.asObservable().pipe(share());
    }
}
