import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Conversation } from '../models/conversation.model';
import { FilterSort } from '../models/filter-sort.model';
import { Message } from '../models/message.model';
import { Paginate } from '../models/paginate.model';
import { User } from '../models/user/user.model';
import { ApiService } from './api.service';

export type MessageServiceUserIndexSortType = (
     'id'
     | 'name'
     | 'surname'
     | 'lastname'
     | 'email'
     | 'type'
)

export interface MessageServiceIndex  {
    limit?: number;
    page?: number;
    q?: string;
    type?: ('default'|'system');
};

export interface MessageServiceNewMessages {
    recipients?: number[];
    title?: string;
    message?: string;
};

export interface MessageServiceUsersIndex {
    limit?: number;
    page?: number;
    sort?: MessageServiceUserIndexSortType|FilterSort<MessageServiceUserIndexSortType>[];
    q?: string;
    group?: number;
    support?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(
        private api: ApiService,
    ) { }

    /**
     * Get list of conversations
     *
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getList(filter?: MessageServiceIndex, maxCacheTime?: number): Observable<Paginate<Conversation>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/messages', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Conversation>(), data);
                data.data = data.data?.map((item: any)=> Conversation.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Post new message
     *
     * @param data
     * @returns
     */
    postMessages(data: MessageServiceNewMessages): Observable<{data: Message}> {
        return this.api.post('/messages', data).pipe(
            map(response => {
                response.data = Message.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * Get a Conversation messages list
     *
     * @param id
     * @param filter
     * @returns
     */
    getItem(id: number, filter?: MessageServiceIndex):  Observable<Paginate<Message>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/messages/' + id, {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Message>(), data);
                data.data = data.data?.map((item: any)=> Message.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get users list
     *
     * @param filter
     * @returns
     */
    getUsers(filter?: MessageServiceUsersIndex): Observable<Paginate<User>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as MessageServiceUserIndexSortType);

        return this.api.get('/messages/users', {params: filter}).pipe(
            map(response => {
                response = Object.assign(new Paginate<User>(), response);
                response.data = response.data?.map((item: number[]) => User.fromJson(item));
                return response;
            })
        );
    }

    /**
     * Get a single user by ID
     *
     * @param id
     * @returns
     */
    getUser(id: number):  Observable<{data: User}> {
        return this.api.get('/messages/users/' + id).pipe(
            map(response => {
                response.data = User.fromJson(response?.data);
                return response;
            })
        );
    }
}
