import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

import { Group } from '../../models/group.model';
import { Paginate } from '../../models/paginate.model';
import { GroupService, GroupServiceGroupFilter } from '../../services/group.service';
import { CourseService, CourseServiceIndex } from '../../services/course.service';
import { Course } from '../../models/course.model';
import { User } from '../../models/user/user.model';
import { UserService, UserServiceIndex, UserServiceRoleType } from '../../services/user.service';
import { MessageService, MessageServiceUsersIndex } from '../../services/message.service';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'filter-course',
    templateUrl: './filter-course.component.html',
    styleUrls: ['./filter-course.component.scss']
})
export class FilterCourseComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected loading: boolean = false;
    protected innerUsers?: Paginate<User>|null;

    courses?: Paginate<Course>;
    coursesFilter: CourseServiceIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        groups_availability: 'available',
        sort: [{
            direction: 'asc',
            field: 'title'
        }, {
            direction: 'desc',
            field: 'updated'
        }]
    };
    selectedCourse: Course|null = null;

    groups?: Paginate<Group>;
    groupsFilter: GroupServiceGroupFilter = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{
            field: 'expiry', direction: 'desc',
        }, {
            field: 'start_date', direction: 'desc',
        }, {
            field: 'end_date', direction: 'asc',
        }],
        include: 'students',
    };
    selectedGroup: Group|null = null;

    users?: Paginate<User>;
    usersMessagesFilter: MessageServiceUsersIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        support: false,
        sort: [{direction:'asc', field: 'type'}, {direction: 'asc', field: 'name'}],
    };
    usersFilter: UserServiceIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{direction:'asc', field: 'type'}, {direction: 'asc', field: 'name'}],
    }
    selectedUser: User|null = null;
    selectedUsers: User[] = [];

    @Output() onCourse: EventEmitter<Course|null> = new EventEmitter;
    @Output() onGroup: EventEmitter<Group|null> = new EventEmitter;
    @Output() onUser: EventEmitter<User|null> = new EventEmitter;
    @Output() onDeselection: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() withAdministration: boolean = false;
    @Input() forMessages: boolean = false;
    @Input() onlyRole: UserServiceRoleType | undefined;
    @Input() withInstallments: boolean = false;

    @Input() course: number|null = null;
    @Input() group: number|null = null;
    @Input() user: number|null = null;

    @Input('users')
    set externalUsers(users: Paginate<User>|null) {
        if (this.innerUsers !== users) {
            this.innerUsers = users;
            this.usersMessagesFilter.page = 1;
            this.users = users || new Paginate<User>();
            this.groups?.reset();
            this.selectedGroup = null;
            this.selectedCourse = null;
            this.selectedUser = null;
        }
    }
    get extenralUsers(): Paginate<User>|null {
        return this.innerUsers || null;
    }

    @Input() level: ('course-group-user'|'course-group'|'course') = 'course-group-user';

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        protected groupService: GroupService,
        protected courseService: CourseService,
        protected userService: UserService,
        protected messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.getCourses();
        if(this.withInstallments) {
            this.groupsFilter.include = ['students','enrollments','installments'];
        }
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    protected getCourses(appendData: boolean = false): void {
        if (appendData && this.courses?.data?.length) {
            if (this.courses?.reachEnd()) {
                return;
            }
            this.coursesFilter.page = this.courses?.nextPage();
        } else if (!this.courses?.data?.length) {
            this.coursesFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.courseService
            .getList(this.coursesFilter)
            .subscribe({
                next: data => {

                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.courses?.data?.length) {
                        data.data = [
                            ...this.courses?.data ?? [],
                            ...data.data,
                        ];
                    } else {
                        if (this.withAdministration) {
                            data.prepend(Course.fromJson({
                                id: 0,
                                title: translate('Администрация'),
                            }))
                        }

                        this.groups?.reset();
                    }

                    this.courses = data;

                    if (this.course && !this.selectedCourse) {
                        this.onCourseToggle(null, this.courses?.data?.find(item => item.id === this.course) || null);
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);
    }

    onScrollCourses(event?: any): void {
        this.getCourses(true);
    }

    onCourseToggle(event: Event|null, course: Course|null): boolean {
        event?.preventDefault();
        this.selectedCourse = this.selectedCourse?.id === course?.id ? null : course;

        if (this.level !== 'course') {
            this.selectedGroup = null;
            this.selectedUser = null;
            this.groups?.reset();
            this.users?.reset();

            if (this.selectedCourse?.id) {
                this.getGroups();
            } else if (this.withAdministration && this.level === 'course-group-user') {
                this.getUsers(false, true);
            }
        }

        this.onCourse.emit(this.selectedCourse);
        this.onDeselection.emit(this.selectedCourse ? false : true);


        return false;
    }

    protected getGroups(appendData: boolean = false): void {
        if (appendData && this.groups?.data?.length) {
            if (this.groups?.reachEnd()) {
                return;
            }
            this.groupsFilter.page = this.groups?.nextPage();
        } else if (!this.groups?.data?.length) {
            this.groupsFilter.page = 1;
        }

        this.groupsFilter.course = this.selectedCourse?.id;

        this.loadingQueue++;
        const subscription = this.groupService
            .getList(this.groupsFilter)
            .subscribe({
                next: data => {

                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.groups?.data?.length) {
                        data.data = [
                            ...this.groups?.data ?? [],
                            ...data.data,
                        ];
                    } else {
                        this.selectedUser = null;
                        this.users?.reset();
                    }
                    this.groups = data;

                    if (this.group && !this.selectedGroup) {
                        this.onGroupToggle(null, this.groups?.data?.find(item => item.id === this.group) || null);
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);;
    }

    onScrollGroups(event?: any): void {
        this.getGroups(true);
    }

    onGroupToggle(event: Event|null, group: Group|null): boolean {
        event?.preventDefault();
        this.selectedGroup = this.selectedGroup?.id === group?.id ? null : group;

        if (this.level === 'course-group-user') {
            this.selectedUser = null;
            this.users?.reset();

            if (this.selectedGroup) {
                this.getUsers();
            }
        }

        this.onGroup.emit(this.selectedGroup);
        this.onDeselection.emit(this.selectedGroup ? false : true);

        return false;
    }

    protected getUsers(appendData: boolean = false, support: boolean = false): void {
        if (this.forMessages) {
            this.getMessageUsers(appendData, support);
        } else {
            this.getGloballyUsers(appendData);
        }
    }

    protected getMessageUsers(appendData: boolean = false, support: boolean = false): void {
        if (appendData && this.users?.data?.length) {
            if (this.users?.reachEnd()) {
                return;
            }
            this.usersMessagesFilter.page = this.users?.nextPage();
        } else if (!this.users?.data?.length) {
            this.usersMessagesFilter.page = 1;
        }

        this.usersMessagesFilter.group = this.selectedGroup?.id;
        this.usersMessagesFilter.support = support;

        this.loadingQueue++;

        const subscription = this.messageService
            .getUsers(this.usersMessagesFilter)
            .subscribe({
                next: data => {
                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.users?.data?.length) {
                        data.data = [
                            ...this.users?.data ?? [],
                            ...data.data,
                        ];
                    }
                    this.users = data;

                    if (this.user && !this.selectedUser) {
                        this.onUserToggle(null, this.users?.data?.find(item => item.id === this.user) || null);
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });

        this.subscriptions.push(subscription);
    }

    protected getGloballyUsers(appendData: boolean = false): void {
        if (appendData && this.users?.data?.length) {
            if (this.users?.reachEnd()) {
                return;
            }
            this.usersFilter.page = this.users?.nextPage();
        } else if (!this.users?.data?.length) {
            this.usersFilter.page = 1;
        }

        this.usersFilter.group = this.selectedGroup?.id;
        this.usersFilter.role = this.onlyRole;

        this.loadingQueue++;

        const subscription = this.userService
            .getList(this.usersFilter)
            .subscribe({
                next: data => {
                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.users?.data?.length) {
                        data.data = [
                            ...this.users?.data ?? [],
                            ...data.data,
                        ];
                    }
                    this.users = data;
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);
    }

    onScrollUsers(event?: any): void {
        this.getUsers(true);
    }

    onUserToggle(event: Event|null, user: User|null): boolean {
        event?.preventDefault();
        this.selectedUser = this.selectedUser?.id === user?.id ? null : user;

        this.onUser.emit(this.selectedUser);
        this.onDeselection.emit(this.selectedUser ? false : true);

        return false;
    }
}
