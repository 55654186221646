
    <a [routerLink]="'/careers/details/' + career?.id" class="job-card d-flex flex-column pt-0 p-4 {{ className }}">
        <div class="job-header d-flex flex-column align-items-center mb-2">
            <div class="job-icon mb-3">
                <div *ngIf="imgLogoTop" class="company-photo img-container ">
                    <img [src]="career?.company?.image" [alt]="career?.company?.title">
                </div>
            </div>
            <h2 class="mb-2">{{ career?.title }}</h2>
        </div>
        <div class="job-content d-flex flex-wrap justify-content-between mt-auto">
            <div class="d-flex flex-column justify-content-between">
                <span class="job-date">
                    <i class="icon-calendar"></i>
                    {{ career?.date | dateLocale:"d MMM YYYY" }}
                </span>
                <div class="primary-btn py-2 px-3 job-btn my-2"
                    [attr.title]="career?.applied ? 'Вече си кандидатствал по тази обява' : null"
                >
                    <i class="fa-solid fa-check text-warning me-2" *ngIf="career?.applied"></i>
                    {{ 'Виж обявата' | transloco }}
                </div>
            </div>

            <div *ngIf="imgLogoLeft" class="img-container mx-1">
                <img [src]="career?.company?.image" [alt]="career?.company?.title || ''" width="100px">
            </div>
        </div>
    </a>
