import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Paginate } from '../models/paginate.model';
import { Observable, map } from 'rxjs';
import { Town } from '../models/town.model';

export interface ProfileServiceListFilter  {
    limit?: number,
    page?: number,
    sort?: string,
    expiration?: string,
};

export type ProfileServiceGroupFilter = {
    limit?: number;
    page?: number;
    expiration?: ('all'|'expired'|'active');
}


@Injectable({
    providedIn: 'root'
})
export class TownService {

    constructor(
        protected api: ApiService,
    ) { }

    getTownList(): Observable<{data: Town[]}> {
        return this.api.get<{data: Town[]}>("/towns").pipe(
            map(response => {
                response.data = response.data?.map((item: any) => Town.fromJson(item));
                return response;
            })
        )
    }
}
