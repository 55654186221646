import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UserRoleName } from '../../models/user/user-role.model';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';

export interface MenuActionsComponentAction {
    text?: string;
    link?: string;
    icon?: string;
    roles?: UserRoleName[];
    divider?: boolean;
    header?: boolean;
    id?: string;
    hide?: boolean;
    disabled?: boolean;
    queryParams?: any;
};

@Component({
    selector: 'menu-actions',
    templateUrl: './menu-actions.component.html',
    styleUrls: ['./menu-actions.component.scss']
})
export class MenuActionsComponent implements OnInit {
    user: User|null = null;

    @Input() defaultEmptyIcon: string = 'fa-solid';
    @Input() smallButtons: boolean = true;
    @Input() dropdownStartFrom: number = 1;
    @Input() dropdownOnly: boolean = false;
    @Input() dropdownIcon: string = 'fa-bars';
    @Input() dropdownClass: string = 'btn-primary';
    @Input() dropdownButtonText: string = '';

    @Input() roles:UserRoleName[] | null = ['admin','marketing','partner','student','teacher'];
    @Input() actions: MenuActionsComponentAction[] = [];

    constructor(
        private authService: AuthService,
    ) { }

    @Output() buttonClicked: EventEmitter<string> = new EventEmitter();

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;
    }

    onButtonClicked(buttonId: string) {
        this.buttonClicked.emit(buttonId);
    }
}
