import { Component, Input, OnInit } from '@angular/core';
import { Module } from '../models/module.model';

@Component({
  selector: 'module-box',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {
    @Input() module?: Module|null = null;
    @Input() number: number = 0;

    constructor() { }

    ngOnInit(): void {
    }

}
