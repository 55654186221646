import { Injectable } from '@angular/core';
import { User } from '../models/user/user.model';
import { UserRoleName } from '../models/user/user-role.model';
import { AuthService } from './auth.service';
import { I18nService } from './i18n.service';

import { filter } from 'rxjs';
import { translate } from '@ngneat/transloco';


export interface NavigationServiceMenu {
    url?: string;
    options?: any;
    title?: string;
    originalTitle: string;
    icon?: string;
    roles?: UserRoleName[];
    shouldHide?: (user?: User) => boolean;
};

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    protected navigation: NavigationServiceMenu[] = [{
        url: "/dashboard",
        title: translate("Начало"),
        originalTitle: "Начало",
        icon: 'icon-house',
    }, {
        url: "/messages",
        title: translate("Съобщения"),
        originalTitle: "Съобщения",
        icon: 'fas fa-envelope fa-fw',
        roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
    }, {
        url: "/profile",
        title: translate("Профил"),
        originalTitle: "Профил",
        icon: 'icon-user',
        roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
    },{
        url: "/courses",
        title: translate("Курсове"),
        originalTitle: "Курсове",
        icon: 'fa-solid fa-book fa-fw',
        roles: ['admin', 'partner'],
    }, {
        url: "/partners",
        title: translate("Партньори"),
        originalTitle: "Партньори",
        icon: 'fa-regular fa-handshake fa-fw',
        roles: ['admin'],
    }, {
        url: "/groups",
        title: translate("Групи"),
        originalTitle: "Групи",
        icon: 'fa-solid fa-chalkboard-user fa-fw',
        roles: ['admin', 'partner', 'teacher', 'student'],
    }, {
        url: "/teachers",
        title: translate("Преподаватели"),
        originalTitle: "Преподаватели",
        icon: 'fa-solid fa-person-chalkboard fa-fw',
        roles: ['admin', 'partner']
    }, {
        url: "/students",
        title: translate("Курсисти"),
        originalTitle: "Курсисти",
        icon: 'fa-solid fa-user-graduate fa-fw',
        roles: ['admin', 'teacher', 'partner','sponsor']
    }, {
        url: "/sponsors",
        title: translate("Спонсори"),
        originalTitle: "Спонсори",
        icon: 'fa-solid fa-user-tie fa-fw',
        roles: ['admin']
    }, {
        url: "/homework",
        title: translate("Домашни и проекти"),
        originalTitle: "Домашни и проекти",
        icon: 'icon-file',
        roles: ['admin', 'partner', 'teacher', 'student'],
    }, {
        url: "/payments",
        title: translate("Плащания"),
        originalTitle: "Плащания",
        icon: 'icon-card',
        roles: ['admin', 'partner','sponsor'],
    // }, {
    //     url: "/certificates",
    //     title: translate("Сертификати"),
    //     originalTitle: "Сертификати",
    //     icon: 'icon-graduation-cap',
    //     roles: ['student', 'admin', 'partner'],
    }, {
        url: "/calendar",
        title: translate("Календар"),
        originalTitle: "Календар",
        icon: 'icon-calendar',
        roles: ['admin', 'partner', 'teacher', 'student'],
    // }, {
    //     url: "/about/instructions",
    //     title: translate("Инструкции"),
    //     originalTitle: "Инструкции",
    //     icon: 'icon-faq',
    //     roles: ['student'],
    // }, {
    //     url: "/careers",
    //     title: translate("Кариерен център"),
    //     originalTitle: "Кариерен център",
    //     icon: 'icon-job',
    //     roles: ['student'],
    // }, {
    //     url: "/jobs",
    //     title: translate("Обяви за работа"),
    //     originalTitle: "Обяви за работа",
    //     icon: 'icon-job',
    //     roles: ['admin', 'marketing', 'partner'],
    // }, {
    //     url: "/blog",
    //     title: translate("Блог"),
    //     originalTitle: "Блог",
    //     icon: 'fa-solid fa-newspaper fa-fw',
    //     roles: ['admin', 'marketing']
    // }, {
    //     url: "/zoom",
    //     title: translate("Zoom"),
    //     originalTitle: "Zoom",
    //     icon: 'fa-solid fa-video fa-fw',
    //     roles: ['admin', 'partner']
    // }, {
    //     url: "/awards",
    //     title: translate("Награди"),
    //     originalTitle: "Награди",
    //     icon: 'fa-solid fa-trophy fa-fw',
    //     shouldHide: (user?: User) => !user?.kid,
    // }, {
    //     url: "/attendances",
    //     title: translate("Събития и записвания"),
    //     originalTitle: "Събития и записвания",
    //     icon: 'fa-solid fa-martini-glass fa-fw',
    //     roles: ['admin', 'marketing'],
    // }, {
    //     url: "/awards",
    //     title: translate("Награди"),
    //     originalTitle: "Награди",
    //     icon: 'fa-solid fa-trophy fa-fw',
    //     roles: ['admin','partner'],
    // }, {
    //     url: "/points",
    //     title: translate("Точки"),
    //     originalTitle: "Точки",
    //     icon: 'fa-solid fa-gift fa-fw',
    //     roles: ['admin','partner', 'teacher'],
    // }, {
    //     url: "/polls",
    //     title: translate("Анкети"),
    //     originalTitle: "Анкети",
    //     icon: 'fa-solid fa-chart-bar fa-fw',
    //     roles: ['admin', 'marketing', 'partner'],
    }];

    constructor(
        private authService: AuthService,
        private i18nService: I18nService,
    ) {


        this.i18nService.onLocaleChanged()
            .pipe(filter(locale => locale.language?.length > 0))
            .subscribe(locale => {
                this.navigation = this.navigation.map(menuItem => {
                    menuItem.title = translate(menuItem.originalTitle);
                    return menuItem;
                });
            });
    }

    getNavigation(): NavigationServiceMenu[] {
        const user = this.authService.getUser() ?? User.fromJson({});

        return this.navigation.filter(menuItem => {
            if (menuItem?.roles?.length && !user?.can(menuItem?.roles)) {
                return false;
            }

            if (menuItem?.shouldHide && typeof menuItem?.shouldHide === 'function') {
                return !menuItem?.shouldHide(user);
            }

            if (!(menuItem?.title?.length && menuItem?.url?.length && menuItem?.icon?.length)) {
                return false;
            }

            return true;
        });
    }
}
