import { User } from "./user/user.model";

export class Message {
    id?: number;
    title?: string;
    message?: string;
    date?: Date;
    sender?: User;
    recipient?: User;
    is_read?: boolean;

    static fromJson(fields: any): Message {
        let item = Object.assign(new this(), fields);

        item.date = fields.date instanceof Date ? fields.date : new Date(fields.date);
        item.sender = User.fromJson(fields?.sender);
        item.recipient = User.fromJson(fields?.recipient);

        return item;
    }
}
