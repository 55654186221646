
<div class="chart-container mt-4 mb-4">
    <div class="btn btn-primary mb-4 btn-backward text-white" (click)="closeReport()">
        <i class="fa-solid fa-backward pe-2"></i>
        {{ 'Назад' | transloco }}
    </div>

    <h2>{{'Присъствие/ активни модули' | transloco }}</h2>

    <div class="chart" style="display: block; width:70%">
        <canvas baseChart
        [data]="moduleData"
        [labels]="moduleChartLabels"
        [type]="moduleChartType"
        [options]="moduleChartOptions"
        [legend]="moduleChartLegend"
        ></canvas>
    </div>

    <h2>{{'Точки от тестове' | transloco }}</h2>

    <div class="chart d-flex" style="display: block">
        <canvas baseChart
        [data]="testsData"
        [type]="testsChartType"
        [legend]="false"
        ></canvas>
        <div class="chart-info ms-5">
            <div class="py-1">{{('Най-висок резултат' | transloco) + ': ' + getMaxScore(testsData.datasets[0].data) }}</div>
            <div class="py-1">{{('Най-нисък резултат' | transloco) + ': ' + getMinScore(testsData.datasets[0].data) }}</div>
            <div class="py-1">{{('Среден резултат' | transloco) + ': ' + getAverageScore(testsData.datasets[0].data) }}</div>
        </div>
    </div>

    <h2>{{'Точки от домашни' | transloco }}</h2>

    <div class="chart d-flex" style="display: block">
        <canvas baseChart
        [data]="homeworksData"
        [type]="homeworksChartType"
        [legend]="false"
        ></canvas>
        <div class="chart-info ms-5">
            <div class="py-1">{{('Най-висок резултат' | transloco) + ': ' + getMaxScore(homeworksData.datasets[0].data) }}</div>
            <div class="py-1">{{('Най-нисък резултат' | transloco) + ': ' + getMinScore(homeworksData.datasets[0].data) }}</div>
            <div class="py-1">{{('Среден резултат' | transloco) + ': ' + getAverageScore(homeworksData.datasets[0].data) }}</div>
        </div>
    </div>


    <div class="report-note">
        <div class="card">
            <div class="card-header py-2 ps-4">
                <i class="fa-solid fa-comment-dots pe-2"></i>
                {{'Бележки' | transloco}}
            </div>
            <div class="card-body p-4">

            </div>
        </div>
    </div>

</div>


