export class FranchiseCourseSummary {
    url?: string;
    title?: string;
    image?: string;
    logo?: string;
    duration?: number;

    static fromJson(fields: any): FranchiseCourseSummary {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
