import { environment } from 'src/environments/environment';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxPayPalModule } from 'ngx-paypal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { TranslocoModule } from '@ngneat/transloco';

import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { CarouselModule } from 'primeng/carousel';

import { AlertComponent } from './components/alert/alert.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppSidemenuComponent } from './components/app-sidemenu/app-sidemenu.component';
import { UserNamePipe } from './pipes/user-name.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CardGroupComponent } from './components/card-group/card-group.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { JobCardComponent } from './components/job-card/job-card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TeacherCardComponent } from './components/teacher-card/teacher-card.component';
import { SidebarCvComponent } from './components/sidebar/cv/cv.component';
import { UploadComponent } from './components/upload/upload.component';
import { ItemTemplateDirective } from './directives/item-template.directive';
import { UploadAvatarComponent } from './components/upload-avatar/upload-avatar.component';
import { RateCardComponent } from './components/rate-card/rate-card.component';
import { RoleAnyPipe } from './pipes/role-any.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PhoneLink } from './pipes/phone-link.pipe';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RestoreTabDirective } from './directives/restore-tab.directive';
import { TabComponent, TabsComponent } from './components/tabs';
import { ListingTableComponent } from './components/listing-table/listing-table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { DatetimeShortPipe } from './pipes/datetime-short.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { UploadBlogComponent } from './components/upload-blog/upload-blog.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ModalPaymentComponent } from './components/modal-payment/modal-payment.component';
import { MessageBubbleComponent } from './components/message-bubble/message-bubble.component';
import { PricePipe } from './pipes/price.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CertificateComponent } from './components/certificate/certificate.component';
import { ModuleComponent } from './module/module.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SidebarMessageComponent } from './components/sidebar/message/message.component';
import { ModalSortItemsComponent } from './components/modal-sort-items/modal-sort-items.component';
import { PageWrapComponent } from './components/page-wrap/page-wrap.component';
import { SectionDirective } from './directives/section.directive';
import { SidebarPaymentsComponent } from './components/sidebar/payments/payments.component';
import { SidebarClassesComponent } from './components/sidebar/classes/classes.component';
import { SidebarCoursesComponent } from './components/sidebar/courses/courses.component';
import { InlineTextComponent } from './components/inline-text/inline-text.component';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
import { PaymentChooserComponent } from './components/payment-chooser/payment-chooser.component';
import { RequiredFieldDirective } from './directives/required-field.directive';
import { FilterCourseComponent } from './components/filter-course/filter-course.component';
import { AppLanguageSwitcherComponent } from './components/app-language-switcher/app-language-switcher.component';
import { EventsComponent } from './components/sidebar/events/events.component';
import { ModalTermsComponent } from './components/modal-terms/modal-terms.component';
import { CardGroupSmallComponent } from './components/card-group-small/card-group-small.component';
import { CardPaymentComponent } from './components/card-payment/card-payment.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { ModalCreateUserComponent } from './components/modal-create-user/modal-create-user.component';
import { ModalEditPaymentComponent } from './components/modal-edit-payments/modal-edit-payment.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MenuActionsComponent } from './components/menu-actions/menu-actions.component';
import { DateLocalePipe } from './pipes/date-locale.pipe';
import { ModalImportCSV } from './components/modal-import-csv/modal-import-csv.component';
import { ModalInstructions } from './components/modal-instructions/modal-instructions.component';
import { GroupReport } from './models/group-report/group-report.model';
import { GroupReportComponent } from './components/group-report/group-report.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
    providers: [
        DatePipe,
        DecimalPipe,
    ],
    declarations: [
        AlertComponent,
        AppHeaderComponent,
        UserNamePipe,
        AppSidemenuComponent,
        AvatarComponent,
        CardGroupComponent,
        CalendarComponent,
        JobCardComponent,
        LoadingComponent,
        TeacherCardComponent,
        SidebarCvComponent,
        UploadComponent,
        ItemTemplateDirective,
        UploadAvatarComponent,
        RateCardComponent,
        RoleAnyPipe,
        SafePipe,
        PhoneLink,
        IsArrayPipe,
        UploadFileComponent,
        BreadcrumbComponent,
        RestoreTabDirective,
        TabComponent,
        TabsComponent,
        PaginationComponent,
        ListingTableComponent,
        ModalConfirmComponent,
        TextEditorComponent,
        DatetimeShortPipe,
        FilterPipe,
        UploadBlogComponent,
        UploadImageComponent,
        MessageBubbleComponent,
        ModalPaymentComponent,
        PricePipe,
        DateAgoPipe,
        CertificateComponent,
        ModuleComponent,
        CarouselComponent,
        SidebarMessageComponent,
        ModalSortItemsComponent,
        PageWrapComponent,
        SectionDirective,
        SidebarPaymentsComponent,
        SidebarClassesComponent,
        SidebarCoursesComponent,
        InlineTextComponent,
        ModalVideoComponent,
        PaymentChooserComponent,
        CardPaymentComponent,
        CardGroupSmallComponent,
        RequiredFieldDirective,
        FilterCourseComponent,
        AppLanguageSwitcherComponent,
        EventsComponent,
        ModalTermsComponent,
        ModalEditPaymentComponent,
        AuthLayoutComponent,
        ModalCreateUserComponent,
        MenuActionsComponent,
        DateLocalePipe,
        ModalImportCSV,
        ModalInstructions,
        GroupReportComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        FullCalendarModule,
        InfiniteScrollModule,
        MatExpansionModule,
        MatTabsModule,
        DragDropModule,
        CKEditorModule,
        NgxPayPalModule,
        CarouselModule,
        provideFirebaseApp(() => initializeApp(environment.services.firebase)),
        provideDatabase(() => getDatabase()),
        TranslocoModule,
        DragScrollModule,
        NgChartsModule,
    ],
    exports: [
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        AlertComponent,
        AppHeaderComponent,
        UserNamePipe,
        AppSidemenuComponent,
        AvatarComponent,
        CardGroupComponent,
        FullCalendarModule,
        CalendarComponent,
        CertificateComponent,
        JobCardComponent,
        InfiniteScrollModule,
        LoadingComponent,
        TeacherCardComponent,
        SidebarCvComponent,
        UploadComponent,
        UploadAvatarComponent,
        RateCardComponent,
        RoleAnyPipe,
        SafePipe,
        PhoneLink,
        IsArrayPipe,
        UploadFileComponent,
        BreadcrumbComponent,
        RestoreTabDirective,
        TabComponent,
        TabsComponent,
        PaginationComponent,
        ListingTableComponent,
        DragDropModule,
        ItemTemplateDirective,
        ModalConfirmComponent,
        CKEditorModule,
        TextEditorComponent,
        DatetimeShortPipe,
        FilterPipe,
        UploadBlogComponent,
        UploadImageComponent,
        MessageBubbleComponent,
        ModalPaymentComponent,
        CardPaymentComponent,
        CardGroupSmallComponent,
        CarouselComponent,
        PricePipe,
        DateAgoPipe,
        SidebarMessageComponent,
        CarouselModule,
        ModalSortItemsComponent,
        SectionDirective,
        PageWrapComponent,
        SidebarPaymentsComponent,
        SidebarClassesComponent,
        SidebarCoursesComponent,
        InlineTextComponent,
        ModalVideoComponent,
        RequiredFieldDirective,
        FilterCourseComponent,
        AppLanguageSwitcherComponent,
        TranslocoModule,
        EventsComponent,
        PaymentChooserComponent,
        ModalTermsComponent,
        ModalEditPaymentComponent,
        AuthLayoutComponent,
        ModalCreateUserComponent,
        MenuActionsComponent,
        DateLocalePipe,
        ModalImportCSV,
        ModalInstructions,
        GroupReportComponent,
    ]
})
export class SharedModule { }
