import { Component, OnInit, Input } from '@angular/core';
import { HomeworkFile } from '../../models/homework-file.model';
import { User } from '../../models/user/user.model';
import { AvatarSize } from '../avatar/avatar.component';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'message-bubble',
  templateUrl: './message-bubble.component.html',
  styleUrls: ['./message-bubble.component.scss']
})
export class MessageBubbleComponent implements OnInit {

    @Input() homework: HomeworkFile = new HomeworkFile;

    @Input() reversed: boolean = false;
    @Input() type?: ('messages'|'homeworks') = 'homeworks';
    @Input() boubleBlue: boolean = true;
    @Input() sender?: User;
    @Input() message?: string = '';
    @Input() created?: Date;
    @Input() fileUrl?: string;
    @Input() fileName?: string = translate('Домашно');
    @Input() showSender?: boolean = true;
    @Input() showRole?: boolean = true;
    @Input() avatarSize: AvatarSize = 'medium';

    constructor() { }

    ngOnInit(): void {

    }
}
