import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { GroupReport } from '../../models/group-report/group-report.model';
import { ChartDataset, ChartOptions, ChartType, ChartConfiguration, ChartData } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

@Component({
    selector: 'group-report',
    templateUrl: './group-report.component.html',
    styleUrls: ['./group-report.component.scss']
})
export class GroupReportComponent implements OnInit {

    public moduleChartOptions: ChartOptions = {
    responsive: true,
    };
    public moduleChartLabels = ['Минали', 'Предстоящи'];
    public moduleData: ChartData<'pie'> = {
    datasets: [
        { data: [] }
    ]
    };
    public moduleChartType: ChartType = 'pie';
    public moduleChartLegend = true;


    public testsData: ChartData<'bar'> = {
    datasets: [
        { data: [], label: 'Тестове' },
    ]
    };
    public testsChartType: ChartType = 'bar';


    public homeworksData: ChartData<'bar'> = {
        datasets: [
            { data: [], label: 'Домашни' },
        ]
    };
    public homeworksChartType: ChartType = 'bar';

    @Input() groupReport: GroupReport|null = null;
    @Output() action: EventEmitter<string> = new EventEmitter();

    ngOnInit(): void {
        this.extractReport();
    }

    extractReport(): void {
        if (this.groupReport && this.groupReport.report) {
            if (this.groupReport?.report?.modules) {
                const activeModules = this.groupReport?.report?.modules.find(m => m.status === 'active')?.count || 0;
                const inactiveModules = this.groupReport.report.modules.find(m => m.status === 'inactive')?.count || 0;
                this.moduleData = {
                    labels: this.moduleChartLabels,
                    datasets: [{ data: [activeModules, inactiveModules] }]
                };
            }
            if (this.groupReport?.report?.tests) {
                this.testsData = {
                    labels: this.groupReport.report.tests.map(t => t.title),
                    datasets: [{
                        data: this.groupReport?.report?.tests.map(t => t.result).map(r => r || 0),
                    }]
                };
            }
            if (this.groupReport?.report?.homeworks) {
                this.homeworksData = {
                    labels: this.groupReport.report.homeworks.map(h => h.title),
                    datasets: [{
                        data: this.groupReport.report.homeworks.map(h => h.result).map(r => r || 0),
                    }]
                };
            }
        }
    }


    closeReport(): void {
        this.action.emit('close');
    }

    getMaxScore(data: number[]): number {
        const filteredData = data.filter(value => isFinite(value) && !isNaN(value));
        return filteredData.length > 0 ? Math.max(...filteredData) : 0;
    }

    getMinScore(data: number[]): number {
        const filteredData = data.filter(value => isFinite(value) && !isNaN(value));
        return filteredData.length > 0 ? Math.min(...filteredData) : 0;
    }

    getAverageScore(data: number[]): number {
        const filteredData = data.filter(value => isFinite(value) && !isNaN(value));
        if (filteredData.length === 0) {
            return 0;
        }
        const sum = filteredData.reduce((acc, val) => acc + val, 0);
        return sum / filteredData.length;
    }


}
