import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FilterSort } from '../models/filter-sort.model';
import { map, Observable } from 'rxjs';
import { Paginate } from '../models/paginate.model';
import { Course, CourseStatus } from '../models/course.model';
import { CourseDesignNested } from '../models/course/course-design-nested';
import { CourseSpotlight } from '../models/course/course-spotlight.model';
import { Module, ModuleStatus } from '../models/module.model';
import { Exercise } from '../models/exercise.model';
import { Theory } from '../models/theory.model';

export type CourseServiceIndexSortType = (
     'id'
    | 'title'
    | 'image'
    | 'description'
    | 'fee'
    | 'price'
    | 'program'
    | 'status'
    | 'url'
    | 'weeks'
    | 'position'
    | 'starting'
    | 'created'
    | 'updated'
);

export type CourseServiceInclude = (
    | 'modules'
    | 'design'
    | 'spotlights'
    | 'topics'
);

export interface CourseServiceIndex  {
    limit?: number;
    page?: number;
    q?: string;
    sort?: CourseServiceIndexSortType|FilterSort<CourseServiceIndexSortType>[];
    include?: string|CourseServiceInclude|CourseServiceInclude[];
    groups_availability?: ('available'|'unavailable');
    status?: ('inactive'|'active'|null);
};

export interface CourseServiceInput {
    title: string;
    description?: string;
    url?: string;
    price?: number;
    presents?: boolean;
    status?: CourseStatus;
    duration?: number;
    starting?: string;
    image?: string;
    logo?: string;
    badge?: string;
    badge_alt?: string;
    design?: number;
    position?: number;
    position_footer?: number;
    spotlights?: number[];
    topics?: Array<{
        title?: string;
        hours?: number;
        status?: CourseStatus;
    }>;
};

export interface CourseServiceModuleInput {
    number?: string;
    title?: string;
    description?: string;
    status?: ModuleStatus;
    homework?: string;
    theory_teacher?: string;
    exercises?: Exercise[];
    theory_elements?: Theory[];
};

export interface CourseServiceFilter {
    include?: string|CourseServiceInclude|CourseServiceInclude[];
};

@Injectable({
    providedIn: 'root'
})
export class CourseService {

    constructor(private api: ApiService) {}

    /**
     * Get list of courses
     *
     * @param filter
     * @returns
     */
    getList(filter?: CourseServiceIndex): Observable<Paginate<Course>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CourseServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Course>(), data);
                data.data = data.data?.map((item: any)=> Course.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get an existing course
     *
     * @param id
     * @param filter
     * @returns
     */
    getItem(id: number, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/' + id, {params: filter}).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Create new course
     *
     * @param input
     * @param filter
     * @returns
     */
    add(input: CourseServiceInput, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));
        input = {
            ...input,
            ...filter ?? {},
        };

        return this.api.post('/courses', input).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing course
     *
     * @param id
     * @param input
     * @param filter
     * @returns
     */
    update(id: number, input: CourseServiceInput, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));
        input = {
            ...input,
            ...filter ?? {},
        };

        return this.api.post('/courses/' + id, input).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete an existing course without having any groups attached
     *
     * @param id
     * @returns
     */
    delete(id: number): Observable<{data: any}> {
        return this.api.delete('/courses/' + id);
    }

    /**
     * Get an existing course
     *
     * @returns
     */
    getDesignList(): Observable<{data: CourseDesignNested[]}> {
        return this.api.get('/courses/designs/list').pipe(
            map(data => {
                data.data = data?.data?.map((item: any) => CourseDesignNested.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get an existing course
     *
     * @returns
     */
    getSpotlightList(): Observable<{data: CourseSpotlight[]}> {
        return this.api.get('/courses/spotlights/list').pipe(
            map(data => {
                data.data = data?.data?.map((item: any) => CourseSpotlight.fromJson(item));
                return data;
            })
        );
    }


    /**
     * Get list of courses
     *
     * @param filter
     * @returns
     */
    getModulesList(id: number, filter?: CourseServiceIndex): Observable<Paginate<Module>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CourseServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/' + id + '/modules', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Module>(), data);
                data.data = data.data?.map((item: any)=> Module.fromJson(item));
                return data;
            })
        );
    }


    /**
     * Get a single module within course
     *
     * @param id
     * @param moduleId
     * @returns
     */
    getModule(id: number, moduleId: number): Observable<{data: Module}> {
        return this.api.get('/courses/' + id + '/modules/' + moduleId).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Create a new course module
     *
     * @param id
     * @param input
     * @returns
     */
    addModule(id: number, input: CourseServiceModuleInput): Observable<{data: Module}> {
        return this.api.post('/courses/' + id + "/modules", input).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing module
     *
     * @param id
     * @param moduleId
     * @param input
     * @returns
     */
    updateModule(id: number, moduleId: number, input: CourseServiceModuleInput): Observable<{data: Module}> {
        return this.api.post('/courses/' + id + '/modules/' + moduleId, input).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete an existing module
     *
     * @param id
     * @param moduleId
     * @returns
     */
    deleteModule(id: number, moduleId: number): Observable<{data: any}> {
        return this.api.delete('/courses/' + id + '/modules/' + moduleId);
    }
}

