import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, OnInit, QueryList, TemplateRef } from '@angular/core';
import { SectionDirective } from '../../directives/section.directive';

@Component({
    selector: 'auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy, AfterContentInit {

    @ContentChildren(SectionDirective) templates?: QueryList<any>;

    content: TemplateRef<any>|null = null;

    @Input() forceShrinkWidth: boolean = false;
    @Input() loading: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch(item.getType()) {
                case 'content':
                    this.content = item.template;
                    break;

                default:

                    break;
            }
        });
    }
}
