import { Injectable } from '@angular/core';
import { GroupVideo } from '../models/group-video.model';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';

export interface VideoInput {
    video?: string,
    description?: string,
    status?: string,
};

@Injectable({
  providedIn: 'root'
})
export class GroupVideoService {

    constructor(
        protected api: ApiService,
    ) { }

    getVideo(id: number, moduleId: number, videoId: number): Observable<{data: GroupVideo}> {
        return this.api.get('/groups/' + id + '/modules/' + moduleId + '/videos/' + videoId).pipe(
            map(data => {
                data.data = GroupVideo.fromJson(data?.data);
                return data;
            })
        );
    }

    addVideo(id: number, moduleId: number, data: VideoInput): Observable  <{data: GroupVideo}> {
        return this.api.post('/groups/' + id + '/modules/' + moduleId + '/videos', data).pipe(
            map(data => {
                data.data = GroupVideo.fromJson(data?.data);
                return data;
            })
        );
    }

    updateVideo(id: number, moduleId: number, videoId: number, data: VideoInput): Observable  <{data: GroupVideo}> {
        return this.api.post('/groups/' + id + '/modules/' + moduleId + '/videos/' + videoId, data).pipe(
            map(data => {
                data.data = GroupVideo.fromJson(data?.data);
                return data;
            })
        );
    }

    deleteStudent(id: number, moduleId: number, videoId: number): Observable<{data: any}> {
        return this.api.delete('/groups/' + id + '/modules/' + moduleId + '/videos/' + videoId);
    }
}
