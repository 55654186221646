
export class Exercise {
    id?: number;
    number?: number;
    description?: string;

    static fromJson(fields: any): Exercise {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
