import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadComponentQueue, UploadComponentType } from '../upload/upload.component';

@Component({
    selector: 'upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

    progress: number|null = null;

    @Input() disabled: boolean = false;
    @Input() files: number = 1;
    @Input() avatar: any = {};
    @Input() loading: boolean = false;
    @Input() withRemove: boolean = true;
    @Input() maxSize: number = 30;
    @Input() allowMimes: string[] = [];
    @Input() variant: ('inline'|'box') = 'box';
    @Input() type: UploadComponentType = 'file';

    @Output() onFinished: EventEmitter<any> = new EventEmitter();
    @Output() onUploaded: EventEmitter<any> = new EventEmitter();
    @Output() onError: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    onFilesFinished(event: any): void {
        this.onFinished.emit(event);
    }

    onFileUploaded(event: any): void {
        this.onUploaded.emit(event);
    }

    onFileError(event: any): void {
        this.onError.emit(event);
    }

    onFilesStarted(event: {files: UploadComponentQueue[]}): void {
        this.progress = null;
    }

    onFileProgress(event: {file: UploadComponentQueue, progress: number, totalProgress: number}): void {
        this.progress = event.totalProgress;
    }

    onAvatarRemove(): void {
        this.onRemove.emit();
    }
}
