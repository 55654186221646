export class ModuleTestType {
    id?: number;
    title?: string;
    description?: string;
    points_multipliers?: number;
    is_final?: boolean;
    is_midterm?: boolean;

    static fromJson(fields: any): ModuleTestType {
        let item = Object.assign(new this(), fields);

        item.is_final = item?.title === 'final';
        item.is_midterm = item?.title === 'midterm';
        return item;
    }
}
