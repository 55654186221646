import { Component, OnInit, Input } from '@angular/core';
import { Career } from '../../models/career.model';

@Component({
  selector: 'job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {
    @Input() className: string|null = null;
    @Input() career: Career|null = null;
    @Input() imgLogoTop : boolean = false;
    @Input() imgLogoLeft : boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

}
