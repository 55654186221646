import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';
import { LangDefinition } from '@ngneat/transloco';
import { AuthService } from '../../services/auth.service';
import { InputUser, UserService } from '../../services/user.service';
import { User } from '../../models/user/user.model';
import { MessageService } from 'primeng/api';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './app-language-switcher.component.html',
  styleUrls: ['./app-language-switcher.component.scss']
})
export class AppLanguageSwitcherComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    @Input() mode: ('codes'|'labels'|'dropdown') = 'codes';

    available: LangDefinition[] = [];
    active: string|null = null;
    activeCode: string|null = null;
    user: User|null = null;

    constructor(
        private i18nService: I18nService,
        private authService: AuthService,
        private userService: UserService,
        private messageService: MessageService,
    ) {

    }

    ngOnInit(): void {
        this.available = this.i18nService.availableLocales();
        this.user = this.authService.getUser();

        const subscription = this.i18nService.onLocaleChanged().subscribe(locale => {
            this.active = locale.locale_posix;
            this.activeCode = locale.language;
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    onSelectLanguage(event: MouseEvent, lang: LangDefinition): boolean {
        event?.preventDefault();
        this.i18nService.changeLanguage(lang.id);
        return false;
    }

    onChangeDropdown(event: {originalEvent: Event, value: string}): void {

        this.i18nService.changeLanguage(event?.value);

        if(this.user) {
            const subscription = this.userService.editLanguage(this?.user?.id ?? 0, {}).subscribe({
                error: error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка при промяната на езика!'),
                    });
                }
            });
        }
    }
}
