import { User } from "./user/user.model";
import { FranchiseCourseSummary } from "./franchise-course-summary.model";
import { FranchisePlan } from "./franchise-plan.model";

export class Franchise {
    id?: number;

    admin?: User;

    town?: {
        name?: string;
        id?: number;
    };
    image?: string;
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    url?: string;
    plan?: FranchisePlan;
    status?: string;
    active_courses?: any[];
    teachers?: any[];
    students?: any[];
    courses?: FranchiseCourseSummary[];

    administrator?: number;
    logo?: string;
    location_url?: string;
    homepage?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;

    preferences?: {
        type?: string;
        visibility?: string;
        name?: string;
        value?: string;
    };

    static fromJson(fields: any): Franchise {
        let item = Object.assign(new this(), fields);
        item.courses = fields?.courses?.map((item: any) => FranchiseCourseSummary.fromJson(item));
        return item;
    }
}
