import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { I18nService } from './i18n.service';

const api = () => `${environment.scheme}://${environment.domain}${environment.port ? ':' + environment.port : ''}/api/`;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    protected readonly API: string = api() || 'http://localhost:4000/api/';
    protected token: string|null = null;

    protected cachedData: {[key: string]: {until: Date, data: any}} = {};

    constructor(
        private http: HttpClient,
        @Optional() @Inject(REQUEST) private ssrRequest: Request,
        private i18nService: I18nService,
    ) { }

    /**
     * Make any type of request
     * @param request Type of the request, i.e. GET, POST, DELETE, etc
     * @param node API node based on default API URL
     * @param options Possible custom options, including "body" and/or "headers"
     */
    request<T>(request: string, node: string, options?: any): Observable<any> {
        return this.http.request<T>(request, this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a GET request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     * @param maxCacheTime How many miliseconds to cache data before real data fetched again. If number is fractional (float point),
     *      current cached data will be flushed (if it has), and a new API request will be initialized.
     */
    get<T>(node: string, options?: any, maxCacheTime: number|null = null): Observable<any> {
        const url = this.buildFullUrl(node);

        // Token is now attached to key, because we want to fetch new data after login/logout user cycle
        //
        // Note that using separate property for cached token won't work, because if we have more than 1 request
        // only the first will invalidate cached data, whereas the next requests will remain cached (token won't be changed for them)
        const cacheKey = url + JSON.stringify(options) + '|' +  this.getToken()?.slice(-40);

        if (maxCacheTime && maxCacheTime > 0) {

            // Check if cache time is fraction (has decimal point) => flush cache immediately
            if (maxCacheTime % 1 !== 0) {
                delete this.cachedData[cacheKey];
            }

            if (cacheKey in this.cachedData && this.cachedData[cacheKey].until > new Date()) {
                return of(this.cachedData[cacheKey].data);
            }

            return this.http.get<T>(this.buildFullUrl(node), this.getOptions(options)).pipe(
                map(data => {
                    this.cachedData[cacheKey] = {
                        until: new Date((new Date).getTime() + maxCacheTime),
                        data,
                    }
                    return data;
                })
            );
        } else {
            delete this.cachedData[cacheKey];

            return this.http.get<T>(this.buildFullUrl(node), this.getOptions(options));
        }
    }

    /**
     * Make a POST request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    post<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.post<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Make a DELETE request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     */
    delete<T>(node: string, options?: any): Observable<any> {
        return this.http.delete<T>(this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a HEAD request
     * @param node API node based on default API URL
     * @param options Possible custom options, eg. "headers"
     */
    head<T>(node: string, options?: any): Observable<any> {
        return this.http.head<T>(this.buildFullUrl(node), this.getOptions(options));
    }

    /**
     * Make a PUT request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    put<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.put<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Make a PATCH request
     * @param node API node based on default API URL
     * @param data Data to be send to the server
     * @param options Possible custom options, eg. "headers"
     */
    patch<T>(node: string, data?: any, options?: any): Observable<any> {
        return this.http.patch<T>(this.buildFullUrl(node), data, this.getOptions(options));
    }

    /**
     * Build a full URL to the API node
     * @param node API node
     */
    buildFullUrl(node: string): string {
        return this.API.replace(/\/+$/, '') + '/' + node.replace(/^\/+/, '');
    }

    /**
     * Update current token
     * @param token
     */
    updateToken(token: string): void {
        if (token && typeof token === 'string') {
            this.token = token;
        }
    }

    hasToken(): boolean {
        return this.token !== null && (this.token || '').length > 0;
    }

    getToken(): string|null {
        return this.hasToken() ? this.token : null;
    }

    getOptions(options: any): any {
        let headers = new HttpHeaders({
            'X-Timezone-Offset': String(new Date().getTimezoneOffset()),
        });

        if (this.token && this.token.length) {
            headers = headers.append('Authorization', `Bearer ${this.token}`);
        }

        const referrer: string =  this.ssrRequest?.headers?.get('referrer') as string
            || this.ssrRequest?.headers?.get('referer') as string
            || '';
        if (referrer?.length) {
            headers = headers.append('X-Referer', referrer);
        }

        const locale: string = this.i18nService.activeLocale()
        if (locale?.length) {
            headers = headers.append('X-Locale', locale);
        }

        // mergeObject doesn't work well with headers
        if (options?.params && options.params instanceof HttpParams) {
            options.headers = headers;
            return options;
        }

        // mergeObject doesn't work well with headers
        if (options?.headers && options.headers instanceof HttpHeaders) {
            options.headers = (options.headers as HttpHeaders).append('X-Timezone-Offset', String(new Date().getTimezoneOffset()));

            if (this.token && this.token.length) {
                options.headers = (options.headers as HttpHeaders).append('Authorization', `Bearer ${this.token}`);
            }

            return options;
        }

        return this.mergeObject({
            ...{headers},
        }, options ? options : {});
    }

    public getSortParams(sort: Array<{field?: string, direction?: ('asc'|'desc')}>): string {
        let output = [];

        for (let item of sort) {
            output.push((item?.direction === 'asc' ? '' : '-') + (item?.field || ''));
        }
        return output.join(',');
    }

    protected mergeObject(target: any, ...sources: any): any {
        if (!sources.length) {
            return target;
        }
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, {[key]: {}});
                    }
                    this.mergeObject(target[key], source[key]);
                } else {
                    Object.assign(target, {[key]: source[key]});
                }
            }
        }

        return this.mergeObject(target, ...sources);
    }

    protected isObject(item: any[]): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }
}

