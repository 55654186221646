import { GroupPollAnswer } from "./group-poll-answer.model";

export class Poll {
    id?: number;
    title?: string;
    type?: string;
    answers?: GroupPollAnswer[];

    static fromJson(fields: any): Poll {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
