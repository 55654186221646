import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
    name: 'dateLocale',
    // pure: true,
})
export class DateLocalePipe implements PipeTransform {
    constructor(
        private datePipe: DatePipe,
        private i18nService: I18nService,
    ) {
    }

    transform(value?: string|Date, format?: string, timezone: any = undefined, locale?: string, invalidDateLabel: string = ''): string | null {
        const isInvalid = value instanceof Date ? isNaN(value.getTime())
            : isNaN(new Date(value ?? '').getTime());

        if (isInvalid) {
            return invalidDateLabel;
        }

        locale = locale || this.i18nService.activeLocale();
        return this.datePipe.transform(value, format, timezone, locale);
    }
}
