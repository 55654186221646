<p-dialog
    [visible]="visible"
    [closable]="false"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
>

    <div class="row modal-start" [ngClass]="this.mode === 'submit' ? 'submit-mode' : ''">
        <div class="col-12 my-md-2 modal-head">
            <div class="row align-items-center">
                <div class="col-auto mb-2">
                    <label for="course">{{ 'Курс:' | transloco }}</label>
                </div>
                <div class="col mb-2">
                    <p-dropdown requiredField requiredFieldAttribute="course"
                                [options]="items"
                                [(ngModel)]="item"
                                (onChange)="onChangeCourse($event)"
                                optionValue="value"
                                optionLabel="title"
                                styleClass="w-100"
                                panelStyleClass="w-100"
                                inputId="course"
                    ></p-dropdown>
                </div>
            </div>
        </div>
        <div class="col-12 modal-info">
            <div class="scrollable nice-scrollbars">
                <div class="px-md-3" [innerHTML]="(terms ?? '') | safe:'html'"></div>
            </div>
        </div>
    </div>

    <form class="standard-form d-flex flex-column" [formGroup]="form" *ngIf="form">
        <div class="col-12 mt-md-3 border-top pt-md-3">
            <div class="mb-md-3 row">
                <label for="name" class="col-12">
                    {{ 'Напишете име и фамилия за потвърждение:' | transloco }}
                </label>

                <div class="col-12">
                    <input  requiredField
                            type="text"
                            id="name"
                            class="form-control"
                            name="name"
                            [placeholder]="'Име и фамилия' | transloco "
                            formControlName="name"
                            [attr.disabled]="loading ? true : null"
                            [class.is-invalid]="errors?.name?.status === 'INVALID'"
                    />

                    <div class="invalid-feedback" *ngIf="errors?.name?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                    <div class="invalid-feedback" *ngIf="errors?.name?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                    <div class="invalid-feedback" *ngIf="errors?.name?.errors?.any">{{ errors?.name?.errors?.any }}</div>
                </div>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-auto text-start">
                <a  class="btn btn-danger mx-2"
                    routerLink="/auth/logout"
                    (click)="visible = false"
                >
                    <i class="fa-solid fa-power-off fa-fw"></i>
                    <span class="d-none d-sm-inline-block ms-1">{{ 'Изход' | transloco }}</span>
                </a>
            </div>
            <div class="col text-right text-nowrap">
                <button *ngIf="mode === 'confirm'"
                        type="button"
                        class="btn btn-primary"
                        [disabled]="loading"
                        (click)="onConfirm($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    <span class="mx-2" >{{ 'Съгласявам се' | transloco }}</span>
                </button>

                <button *ngIf="mode === 'submit'"
                        type="button"
                        class="btn btn-primary"
                        [disabled]="loading"
                        (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    <span class="mx-2" >{{ 'Потвърждавам' | transloco }}</span>
                </button>
            </div>
        </div>
    </ng-template>

</p-dialog>
