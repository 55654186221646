import { Course } from "./course.model";
import { Group } from "./group.model";
import { Homework } from "./homework.model";

export class ModuleHomework {
    id?: number;
    number?: string;
    description?: string;
    title?: string;
    status?: ('active'|'inactive');
    course?: Course;
    group?: Group;
    homework?: Homework;

    static fromJson(fields: any): ModuleHomework {
        let item = Object.assign(new this(), fields);
        item.homework = Homework.fromJson(fields?.homework);
        return item;
    }

    toggleStatus(): ('active'|'inactive') {
        return this.status === 'active' ? 'inactive' : 'active';
    }
}
