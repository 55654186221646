import { UntypedFormGroup, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { GroupPaymentType } from 'src/app/shared/models/group-payment-type.model';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { User } from 'src/app/shared/models/user/user.model';
import { GroupService } from 'src/app/shared/services/group.service';
import { environment } from 'src/environments/environment';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { Payment } from 'src/app/shared/models/payment.model';
import { PaymentService } from '../../services/payment.service';


@Component({
    selector: 'modal-edit-payment',
    templateUrl: './modal-edit-payment.component.html',
    styleUrls: ['./modal-edit-payment.component.scss']
})
export class ModalEditPaymentComponent {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onResult: EventEmitter<Payment> = new EventEmitter();
    @Output() onAction: EventEmitter<{action: string}> = new EventEmitter();
    @Input() closable: boolean = true;
    @Input() title: string = translate('Добавяне на курсист');
    @Input() user: User|null|undefined = null;
    @Input() payment: Payment = new Payment;

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected debounceTimeout: number = 750;
    protected searchDebounce: any = null;

    loading: boolean = false;
    form: UntypedFormGroup|null = null;
    errors: any = {};
    step: ('users'|'payments') = 'users';
    paymentTypeList?: Paginate<GroupPaymentType>;
    paymentTypes: Array<{id: number|null, name: string}> = [];

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    get paymentControls(): FormArray<FormGroup>|null {
        return this.form?.get('payments') as FormArray;
    }

    constructor(
        private payentService: PaymentService,
        private formBuilder: FormBuilder,
        private groupService: GroupService,
        private apiValidation: ApiValidation,
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.getPaymentType();
    }

    protected initForm() {
        this.errors = {};
        this.form = this.formBuilder.group({

            type: [this?.payment?.type?.id ?? this.paymentTypes?.[1]?.id ?? null, [Validators.required]],
            price: [Number(this.payment?.price?.bgn ?? '') ?? null, [
                Validators.max(10000),
                Validators.pattern(/^[0-9.,]+$/),
                Validators.required,
            ]],
            date: [this?.payment?.due ? new Date(this?.payment?.due) : '', [Validators.required]],
            description: [this?.payment?.description?.trim() ?? null, [Validators.maxLength(255)]],
            status: [this?.payment?.status === 'paid' , []],
        });
    }

    onCloseAction(e?: any): void {
        this.onAction.emit({action: 'close'});
    }

    onSubmit(event?: Event): void {
        if (this.loading) {
            return;
        }

        if (this.form?.invalid) {
            this.errors = this.form?.controls;

            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            this.form.markAllAsTouched();

            return;
        }

        const formRawData = this.form?.getRawValue();

        formRawData.status = formRawData.status === true ? 'paid' : 'unpaid';
        formRawData.price = Number(('' + (formRawData.price || ''))?.replace(',', '.')) || 0;

        this.loadingQueue++;
        const subscription = this.payentService.edit(this.payment?.id ?? 0, formRawData).subscribe({
            next: response => {
                this.loadingQueue--;
                this.onResult.emit(response.data);
                this.onCloseAction();
            },
            error: error => {
                this.loadingQueue--;

                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        detail: data.first || translate("Възникна грешка. Вероятно линкът не е актуален вече."),
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getPaymentType(): void {
        this.loadingQueue++;

        const subscription = this.groupService.getInstallmentTypeList(this.CACHING_MAX_TIME).subscribe({
            next: (data: Paginate<GroupPaymentType>) => {
                this.paymentTypes = data.data ? data.data?.map((item: any) => {

                    return {
                        id: item?.id ?? 0,
                        name: item?.title,
                    };
                }) : [];

                this.paymentTypes?.unshift({
                    id: null,
                    name: translate('< Тип на вноска >'),
                });

                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }
}
