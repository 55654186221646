<!-- @ToDo: Make this dynamically -->
<div class="lection mb-4 d-none">
    <h3 class="mb-5">
        {{ 'Предстоящи занятия:' | transloco }}
        <div class="separator d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>

    <div class="lection-card mb-3">
        <h4 class="m-0">Понеделник</h4>
        <span class="date">21.03.2022</span>
        <div class="lectino-info d-flex justify-content-between mt-3">
            <p class="m-0">JAVASCRIPT FUNDAMENTALS</p>
            <span class="d-none d-sm-flex">18:00 - 21:00ч.</span>
        </div>
    </div>

    <div class="lection-card mb-3">
        <h4 class="m-0">Понеделник</h4>
        <span class="date">21.03.2022</span>
        <div class="lectino-info d-flex justify-content-between mt-3">
            <p class="m-0">JAVASCRIPT FUNDAMENTALS</p>
            <span class="d-none d-sm-flex">18:00 - 21:00ч.</span>
        </div>
    </div>
</div>
