class PaginateLinks {
    public first?: string|null = null;
    public last?: string|null = null;
    public prev?: string|null = null;
    public next?: string|null = null;
};

class PaginateMetaLink {
    public url?: string|null = null;
    public label?: string|number = '';;
    public active?: boolean = false;
};

class PaginateMeta {
    public path?: string = '/';
    public links?: PaginateMetaLink[];

    public from: number = 0;
    public to: number = 0;

    public current_page: number = 0;
    public last_page: number = 0;
    public per_page: number = 1;

    private innerTotal: number = 0;
    public set total(val: number) {
        if (this.innerTotal !== val) {
            this.innerTotal = val;
            this.innerTotal < 0 && (this.innerTotal = 0);
        }
    }
    public get total(): number {
        return this.innerTotal;
    }
};

export class Paginate<T> {
    public data?: Array<T>;
    public links: PaginateLinks;
    public meta: PaginateMeta;

    constructor() {
        this.data = [];
        this.meta = new PaginateMeta();
        this.links = new PaginateLinks();
    }

    /**
     * Remove all data
     */
    reset(): void {
        this.data = [];
        this.meta = new PaginateMeta();
        this.links = new PaginateLinks();
    }

    /**
     * Get next page of pagination
     */
    nextPage(): number {
        if (this.meta?.current_page && this.meta?.last_page && this.meta?.current_page < this.meta?.last_page) {
            return ++this.meta.current_page;
        }
        return 0;
    }

    /**
     * Get previous page of pagination
     */
    prevPage(): number {
        if (this.meta?.current_page && this.meta?.current_page > 1) {
            return --this.meta.current_page;
        }
        return 0;
    }

    /**
     * Get previous page of pagination
     */
    pagesCount(): number {
        return Math.ceil((this.meta?.total ?? 0) / (this.meta?.per_page ?? 1));
    }


    /**
     * Check if pagination is reach the end
     */
    reachEnd(): boolean {
        return this.meta?.current_page && this.meta?.last_page
            ? (this.meta?.current_page >= this.meta?.last_page)
            : false;
    }

    /**
     * Check if pagination is in the start
     * @returns
     */
    reachStart(): boolean {
        return this.meta?.current_page === 1;
    }

    /**
     * Set Data for pagination
     *
     * @param data
     */
    setData(data: Array<T>) {
        this.data = data;
    }

    /**
     * Add new item at the beginning of items
     * @param item
     */
    append(item: T|T[]): void {
        item = Array.isArray(item) ? item : [item];
        this.data?.push(...item);
        this.increaseItems(item?.length);
    }

    /**
     * Add new item at the ending of items
     * @param item
     */
    prepend(item: T|T[]): void {
        item = Array.isArray(item) ? item : [item];
        this.data?.unshift(...item);
        this.increaseItems(item?.length);
    }

    protected increaseItems(count: number = 1) {
        this.meta.total += count;
        this.meta.to += count;
        this.meta.last_page = Math.ceil(this.meta.total / this.meta.per_page);
        this.links.last = this.links?.last?.replace(/=(\d+)$/, '=' + this.meta.last_page);
    }
};
