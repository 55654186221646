import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NavigationService, NavigationServiceMenu } from '../../services/navigation.service';


@Component({
    selector: 'app-sidemenu',
    templateUrl: './app-sidemenu.component.html',
    styleUrls: ['./app-sidemenu.component.scss']
})
export class AppSidemenuComponent implements OnInit, AfterContentInit {

    navigation: NavigationServiceMenu[] = [];

    constructor(
        private navigationService: NavigationService,
    ) { }

    ngOnInit(): void {
        this.navigation = this.navigationService.getNavigation();
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            this.showTooltip();
        });
    }

    showTooltip() {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            // @ts-ignore
            new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }

    onNavItemLeave(event: Event, menuItem: HTMLElement): void {
        // @ts-ignore
        const tooltip = bootstrap.Tooltip.getInstance(menuItem);
        tooltip && tooltip?.hide();
    }
}
