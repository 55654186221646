import { Component, Input } from '@angular/core';
import { Group } from 'src/app/shared/models/group.model';

@Component({
    selector: 'sidebar-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent {

    @Input() headline: string|null = null;
    @Input() group: Group = new Group;
}
