<div class="cv mb-3 d-xl-flex d-none">
    <div class="cv-img">
        <img src="/assets/img/icons/cv.svg" alt="cv-img">
    </div>
    <div class="cv-cont d-flex flex-column">
        <h3 class="mb-auto">
            {{ 'Твоето CV' | transloco }}
            <div class="separator secondary d-flex align-items-center mt-3">
                <span></span>
                <i class="fa-solid fa-ellipsis mx-2"></i>
            </div>
        </h3>
        <a *ngIf="user.cv?.url" [href]="user.cv?.url" target="_blank" class="primary-btn w-100 px-2 text-center text-nowrap">{{ 'Виж тук' | transloco }}</a>
        <a *ngIf="!user.cv?.url" [routerLink]="['/profile/cv']" class="primary-btn w-100 px-2 text-center text-nowrap">{{ 'Създай CV' | transloco }}</a>
    </div>
</div>

<div class="cv-mobile mb-4 cv-side d-xl-none d-flex justify-content-between">
    <h3>
        {{ 'Твоето CV' | transloco }}
        <div class="separator secondary d-flex align-items-center mt-3">
            <span></span>
            <i class="fa-solid fa-ellipsis mx-2"></i>
        </div>
    </h3>
    <a *ngIf="user.cv?.url" [href]="user.cv?.url" target="_blank" class="primary-btn secondary align-self-center">{{ 'Виж тук' | transloco }}</a>
    <a *ngIf="!user.cv?.url" [routerLink]="['/profile/cv']" class="primary-btn secondary align-self-center">{{ 'Създай CV' | transloco }}</a>
</div>
