import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';
import { NavigationService, NavigationServiceMenu } from '../../services/navigation.service';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    user: User|null = null;
    now: Date = new Date();
    navigation: NavigationServiceMenu[] = [];

    constructor(
        protected authService: AuthService,
        protected navigationService: NavigationService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;

        const authSubscription = this.authService.getStatus().subscribe(status => {
            this.user = this.authService.getUser();
        });
        this.subscriptions.push(authSubscription);

        this.navigation = this.navigationService.getNavigation();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

}
