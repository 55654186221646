export class UserImportResultSummary {
    new?: number;
    old?: number;
    failed?: number;
    total?: number;

    static fromJson(fields: any) {
        let item = Object.assign(new this(), fields);
        return item;
    }

}
