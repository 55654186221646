import { Group } from "../group.model";
import { GroupReportData } from "./group-report-data";
export class GroupReport {
    group?: Group;
    report?: GroupReportData;

    static fromJson(fields: any): GroupReport {
        let item = Object.assign(new this(), fields);
        item.group = Group.fromJson(fields?.group);
        item.report = GroupReportData.fromJson(fields?.report);
        return item;
    }
}
