<div class="d-flex flex-column payment-type-wrapper">
    <label [for]="'paymentChooserLabel' + id">{{label}}</label>

    <div class="inputs d-flex flex-wrap">
        <div *ngFor="let item of items"
            class="flex align-items-center"
            [class.is-invalid]="errors?.invalid && errors?.touched"
        >
            <input  requiredField [requiredFieldAttribute]="'paymentChooserLabel' + id"
                    type="radio"
                    class="form-check-input"
                    [name]="name"
                    [id]="id + '_' + item?.value"
                    [value]="item.value"
                    [checked]="item.value === selectedValue"
                    (change)="onItemClick(item)"
                    />
            <label  [for]="id + '_' + item?.value"
                    [class.invalid]="errors?.invalid && errors?.touched"
                    [pTooltip]="item.title"
                    tooltipPosition="bottom"
            >
                    <i [class]="'fa-solid mx-2 py-2 fa-fw ' + item.icon"></i>
            </label>
        </div>

        <div *ngIf="errors?.errors?.maxlength" class="invalid-feedback mt-0">{{ 'Не може да надвишава 10000' | transloco }}</div>
        <div *ngIf="errors?.errors?.required" class="invalid-feedback mt-0">{{ 'Задължително поле' | transloco }}</div>
    </div>
</div>
