<loading [loading]="loading" *ngIf="!courses?.data?.length"></loading>

<drag-scroll class="courses-wrapper d-flex flex-nowrap nice-scrollbars p-0"
             infinite-scroll
             [scrollWindow]="false"
             [horizontal]="true"
             infiniteScrollContainer=".drag-scroll-content"
             (scrolled)="onScrollCourses($event)"
>
    <ng-container *ngFor="let course of courses?.data">
        <div *ngIf="(course?.id ?? 0) > 0"
            class="col course me-1 me-xl-3"
            [class.selected]="selectedCourse?.id === course?.id"
            (click)="onCourseToggle($event, course)"
        >
            <div class="name">{{ course?.title }}</div>
            <loading [loading]="loading"></loading>
            <img [src]="course?.image" [alt]="course?.title" [title]="course?.title" />
        </div>

        <div *ngIf="!(course?.id ?? 0)"
            class="col course me-3 me-xl-4 highlight"
            [class.selected]="selectedCourse?.id === course?.id"
            (click)="onCourseToggle($event, course)"
        >
            <div class="name"></div>
            <div class="cover text-center d-flex flex-column align-items-center justify-content-center">
                <i class="fa-solid fa-user-gear fa-fw fa-3x mt-auto mb-xl-2"></i>
                <h2 class="text-center text-uppercase mt-2 mb-auto">{{ course?.title }}</h2>
            </div>
        </div>
    </ng-container>
</drag-scroll>


<drag-scroll class="groups-wrapper d-flex flex-nowrap nice-scrollbars mt-4"
             infinite-scroll
             [scrollWindow]="false"
             [horizontal]="true"
             infiniteScrollContainer=".drag-scroll-content"
             (scrolled)="onScrollGroups($event)"
             *ngIf="groups?.data?.length"
>
    <div *ngFor="let group of groups?.data"
         class="col-12 group me-2"
         [class.expired]="group?.expired"
         [class.selected]="selectedGroup?.id === group?.id"
         (click)="onGroupToggle($event, group)"
    >
        <div class="row flex-nowrap flex-row align-items-center">
            <div class="col-auto position-relative">
                <img [src]="group?.course?.logo" [alt]="group?.title" [title]="group?.title">
                <loading [loading]="loading"></loading>
            </div>
            <div class="col">
                <h3>{{ group?.title }}</h3>
                <p>{{ group?.parsed_date_start | dateLocale:'dd.MM.yyyy'}} - {{ group?.parsed_date_to | dateLocale:'dd.MM.yyyy' }}</p>
            </div>
        </div>
    </div>
</drag-scroll>

<div class="users-wrapper">
    <drag-scroll class="users-container d-flex flex-nowrap nice-scrollbars mt-4 w-100"
                 infinite-scroll
                 [scrollWindow]="false"
                 [horizontal]="true"
                 (scrolled)="onScrollUsers($event)"
                 [ngClass]="{'no-users': users?.data?.length === 0}"
                 infiniteScrollContainer=".drag-scroll-content"
    >
        <div    *ngFor="let user of users?.data"
                (click)="onUserToggle($event, user)"
                class="d-inline-flex flex-column align-items-center justify-content-start cursor-pointer tabUser"
        >
            <avatar class="mt-3 mx-3 mb-1"
                    [user]="user || null"
                    size="big"
                    type="drop"
                    [selected]="selectedUser?.id === user?.id"
                    [highlight]="selectedUser?.id === user?.id"
                    [loading]="loading"
            ></avatar>

            <p class="mb-3 mt-2">{{user | userName }}</p>
        </div>
    </drag-scroll>
</div>
