import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/shared/models/group.model';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { GroupService, GroupServiceGroupFilter } from 'src/app/shared/services/group.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sidebar-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.scss']
})
export class SidebarCoursesComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.courses;
    protected readonly CACHING_MAX_TIME: number = environment.caching.sidebar;

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;

    @Input() withActive: boolean = true;
    @Input() withExpired: boolean = true;
    @Input() baseUrl: string = '/groups/';

    loading: boolean = false;

    activeGroups: Paginate<Group> = new Paginate<Group>();
    activeGroupsFilter: GroupServiceGroupFilter = {
        expiration: 'active',
        limit: this.PAGINATION_LIMIT,
        page: 1,
    };

    expiredGroups: Paginate<Group> = new Paginate<Group>();
    expiredGroupsFilter: GroupServiceGroupFilter = {
        expiration: 'expired',
        limit: this.PAGINATION_LIMIT,
        page: 1,
    };

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        protected groupService: GroupService,
    ) { }

    ngOnInit(): void {
        this.getActiveGroups();
        this.getExpiredGroups();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    protected getActiveGroups(appendData: boolean = false):void {
        if (!this.withActive) {
            return;
        }

        if (appendData && this.activeGroups?.data?.length) {
            if (this.activeGroups?.reachEnd()) {
                return;
            }
            this.activeGroupsFilter.page = this.activeGroups?.nextPage();
        } else if (!this.activeGroups?.data?.length) {
            this.activeGroupsFilter.page = 1;
        }

        this.loadingQueue++;

        const subscription = this.groupService.getList(this.activeGroupsFilter, this.CACHING_MAX_TIME).subscribe({
            next: (data) => {
                this.loadingQueue--;

                if (appendData && this.activeGroups) {

                    data.data = [
                        ...this.activeGroups?.data ?? [],
                        ...data?.data ?? [],
                    ];
                }
                this.activeGroups = data;
            },
            error: error => {
                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }

    protected getExpiredGroups(appendData: boolean = false):void {
        if (!this.withExpired) {
            return;
        }

        if (appendData && this.expiredGroups?.data?.length) {
            if (this.expiredGroups?.reachEnd()) {
                return;
            }
            this.expiredGroupsFilter.page = this.expiredGroups?.nextPage();
        } else if (!this.expiredGroups?.data?.length) {
            this.expiredGroupsFilter.page = 1;
        }

        this.loadingQueue++;

        const subscription = this.groupService.getList(this.expiredGroupsFilter, this.CACHING_MAX_TIME).subscribe({
            next: (data) => {
                this.loadingQueue--;

                if (appendData && this.expiredGroups) {

                    data.data = [
                        ...this.expiredGroups?.data ?? [],
                        ...data?.data ?? [],
                    ];
                }
                this.expiredGroups = data;
            },
            error: error => {
                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }

    onLoadMore(event: Event, type: ('active'|'expired')) {
        event?.preventDefault();

        if (type === 'active') {
            this.getActiveGroups(true);
        } else if (type === 'expired') {
            this.getExpiredGroups(true);
        }
    }
}
