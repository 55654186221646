export class StudentGroupVerificaton {
    approved: boolean = false;
    confirmed: boolean = false;
    certificate: boolean = false;
    type: boolean = false;

    static fromJson(fields: any): StudentGroupVerificaton {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
