import { Injectable, inject } from '@angular/core';
import { Database, getDatabase, ref, onValue, DataSnapshot } from '@angular/fire/database';
import { translate } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, share } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class RuntimeService {
    private readonly BUILD_NAME: string = 'build';
    private readonly DATABASE_PATH: string = 'build';

    protected subjectRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private database: Database = inject(Database);

    constructor() {
        if (typeof localStorage === 'undefined') {
            return;
        }

        const build = this.getBuild();
        const db = getDatabase(this.database.app);
        const reference = ref(db, `${this.DATABASE_PATH}/${environment?.name}`);

        onValue(reference, (snapshot: DataSnapshot) => {
            const buildSnapshot = '' + (snapshot.val() ?? '');

            if (build !== buildSnapshot) {
                const buildUnset: boolean = !build || !build?.length;

                localStorage.setItem(this.BUILD_NAME, buildSnapshot ?? '');

                this.subjectRefresh.next(true);
            }
        });
    }

    getBuild(): string {
        return localStorage?.getItem(this.BUILD_NAME) ?? '';
    }

    /**
     * Returns status for refresh
     */
    askRefresh(): Observable<boolean> {
        return this.subjectRefresh.asObservable().pipe(share());
    }
}
