export class UserRegistration {
    id?: number;
    group?: number;
    type?: string;
    name?: string;
    value?: string;
    filename?: string;
    mime?: string;
    size?: number;

    static fromJson(fields: any): UserRegistration {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
