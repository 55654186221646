import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { GroupService, VideoInput } from '../../services/group.service';
import { GroupVideoService } from '../../services/group-video.service';
import { ApiValidation } from '../../services/api-validation.service';
import { GroupVideo } from '../../models/group-video.model';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss']
})
export class ModalVideoComponent {
    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onAction: EventEmitter<{action: string}> = new EventEmitter();
    @Input() closable: boolean = true;
    @Input() groupId: number = 0;
    @Input() moduleId: number = 0;
    @Input() mode: ('add'|'edit') = 'add';
    @Input() videoId: number = 0;
    @Input() title: string = translate('Добавяне на видео');

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;

    form: UntypedFormGroup|null = null;
    errors: any = {};
    statusList: Array<{id: string, name: string}> = [{
        id: 'active',
        name: translate('Активен'),
    }, {
        id: 'inactive',
        name: translate('Неактивен'),
    }];
    loading: boolean = false;
    video: GroupVideo|null = new GroupVideo;


    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private messageService: MessageService,
        private apiValidation: ApiValidation,
        private groupVideoService: GroupVideoService,
    ) { }

    ngOnInit(): void {
        this.getVideo();
        this.initForm();
    }

    onCloseAction(e?: any): void {
        this.onAction.emit({action: 'close'});
    }

    protected initForm() {
        this.errors = {};

        this.form = new UntypedFormGroup({
            video: new UntypedFormControl(this.video?.video ?? '', [
                Validators.minLength(2),
                Validators.maxLength(255),
                Validators.required
            ]),
            description: new UntypedFormControl(this.video?.description ?? '', [
                Validators.maxLength(255),
            ]),
            status: new UntypedFormControl(this.video?.status ?? 'active', [Validators.pattern('^(active|inactive)$')]),
        });
    }

    protected getVideo(): void {
        if (!this.videoId) {
            return;
        }

        this.loadingQueue++;

        const subscription = this.groupVideoService.getVideo(this.groupId, this.moduleId, this.videoId).subscribe({
            next: (data: any) => {
                this.video = data.data;

                this.initForm();
                this.loadingQueue--;
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка, видеото не е намерено!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });

        this.subscriptions.push(subscription);
    }

    onSubmit(event?: any): void {
        if (this.loading) {
            return;
        }

        this.form?.get('video')?.setValue((this.form?.get('video')?.value || '').trim());
        this.form?.get('description')?.setValue((this.form?.get('description')?.value || '').trim());
        this.form?.get('status')?.setValue((this.form?.get('status')?.value || '').trim());
        this.form?.updateValueAndValidity();

        if (this.form?.invalid) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!')
            });
            this.loadingQueue--;
            return;
        }

        const formData: VideoInput = {
            video: this.form?.get('video')?.value || '',
            description: this.form?.get('description')?.value || '',
            status: this.form?.get('status')?.value || '',
        }

        const action = this.video?.id && this.mode === 'edit'
        ? this.groupVideoService.updateVideo(this.groupId, this.moduleId, this.videoId, formData)
        : this.groupVideoService.addVideo(this.groupId, this.moduleId, formData);

        const subscription = action.subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно добавен запис'),
                });
                this.onAction.emit({action: 'send'});
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });

        this.subscriptions.push(subscription);
    }

    abortSubmit(): void {
        this.onAction.emit({action: 'close'});
    }

}
