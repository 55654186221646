export class CourseDesign {
    id?: number;
    design?: string;
    name?: string;
    url?: string;
    contract?: string;
    position?: number;
    parent?: CourseDesign;

    static fromJson(fields?: any): CourseDesign {
        let item = Object.assign(new this(), fields);
        item.parent = item?.parent ? CourseDesign.fromJson(item?.parent) : null;
        return item;
    }
}
