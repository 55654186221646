import { CourseShort } from "./course-short.model";
import { Course } from "./course.model";
import { GroupShort } from "./group-short.model";
import { User } from "./user/user.model";

export class GroupStudent {
    id?: number;
    type?: string;
    approved?: boolean;
    approved_date?: string;
    approved_name?: string;
    approval_admin?: string;
    without_certificate?: boolean;
    confirmed?: boolean;
    student?: User;
    group?: GroupShort;
    course?: CourseShort;
    status?: string;

    static fromJson(fields: any): GroupStudent {
        let item = Object.assign(new this(), fields);

        item.student = User.fromJson(fields?.student);
        item.course = CourseShort.fromJson(fields?.course);
        return item;
    }
}
