import { AfterContentInit, Component, ContentChild, ContentChildren, ElementRef, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user/user.model';
import { Subscription } from 'rxjs';
import { SectionDirective } from '../../directives/section.directive';

export interface  PageWrapComponentBreadcrumbItem {
    title: string,
    link?: string,
};

@Component({
    selector: 'page-wrap',
    templateUrl: './page-wrap.component.html',
    styleUrls: ['./page-wrap.component.scss']
})
export class PageWrapComponent implements OnInit, OnDestroy, AfterContentInit {
    protected subscriptions: Subscription[] = [];
    protected navigation: PageWrapComponentBreadcrumbItem[] = [];

    breadcrumb: MenuItem[] = [];
    isUserGuest: boolean = true;
    user: User = new User;

    @ContentChildren(SectionDirective) templates?: QueryList<any>;
    contentMain?: TemplateRef<any>;
    contentCover?: TemplateRef<any>;
    contentActions?: TemplateRef<any>;
    contentSidebar?: TemplateRef<any>;
    contentHeadline?: TemplateRef<any>;

    @Input() headline: string|null = null;
    @Input() headlineType: ('default'|'short') = 'default';
    @Input() headlineImage: string|null = null;
    @Input() fullWidth: boolean = false;
    @Input() forceShrinkWidth: boolean = false;
    @Input() withSideMenu: boolean = true;
    @Input() loading: boolean = false;

    @Input('navigation')
    set buildNavigation(items: PageWrapComponentBreadcrumbItem[]) {
        if (items !== this.navigation) {
            this.navigation = items;
            this.breadcrumb = this.navigation.map(item => {
                const result: MenuItem = {
                    label: item.title ?? '',
                    routerLink: item?.link ?? null,
                };
                return result;

            }).filter(item => item.label?.length).slice();
        }
    }

    constructor(
        protected authService: AuthService,
    ) { }

    ngOnInit(): void {
        const authSubscription = this.authService.getAuthReady().subscribe(status => {
            this.loading = !status;

            this.user = this.authService.getUser() ?? new User;
            this.isUserGuest = this.user.isGuest();
        });
        this.subscriptions.push(authSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch(item.getType()) {
                case 'sidebar':
                    this.contentSidebar = item.template;
                    break;

                case 'cover':
                    this.contentCover = item.template;
                    break;

                case 'actions':
                    this.contentActions = item.template;
                    break;

                case 'headline':
                    this.contentHeadline = item.template;
                    break;

                case 'content':
                default:
                    this.contentMain = item.template;
                    break;
            }
        });
    }

}
