export class Preference {
    type?: string;
    name?: string;
    value?: string | boolean;
    static fromJson(fields:any):Preference {
        let item = Object.assign(new this(), fields);
        return item;
    }
}


export class PreferenceData {
    preferences?: Preference[]
    static fromJson(fields: any): Preference {
        let item = Object.assign(new this(), fields);
        fields.preferences = fields?.preferences?.map((item: any) => Preference.fromJson(item))
        return item;
    }
}
