import { Group } from "../group.model";
import { GroupReportHomework } from "./group-report-homework.model";
import { GroupReportModuleCount } from "./group-report-module-count.model";
import { GroupReportTest } from "./group-report-test.model";
export class GroupReportData {
    modules?: GroupReportModuleCount[];
    tests?: GroupReportTest[];
    homeworks?: GroupReportHomework[];

    static fromJson(fields: any): GroupReportData {
        let item = Object.assign(new this(), fields);
        item.modules = fields?.modules?.map((module: GroupReportModuleCount) => GroupReportModuleCount.fromJson(module));
        item.tests = fields?.tests?.map((test: GroupReportTest) => GroupReportTest.fromJson(test));
        item.homeworks = fields?.tests?.map((homework: GroupReportHomework) => GroupReportHomework.fromJson(homework));
        return item;
    }
}
