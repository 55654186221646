<header class="border-bottom py-2">
    <nav class="navbar navbar-expand-md" aria-label="Fourth navbar example">
        <div class="container-fluid">
            <div class="navbar-brand d-none d-md-inline-flex">
                <a routerLink="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-decoration-none" [title]="'Начало' | transloco">
                    <img src="/assets/img/logos/logo.svg" alt="">
                </a>
                <a href="https://t.me/+86ohSNPnHEY0MDEy" class="text-nowrap text-white contact-number d-flex align-items-center" target="_blank">Telegram Group</a>

            </div>

            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="fa-solid fa-bars"></i>
            </button>

            <app-language-switcher mode="dropdown" class="me-auto d-md-none lang-switcher mobile"></app-language-switcher>

            <div class=" justify-content-end d-block d-md-none d-lg-none d-xl-none">
                <ul class="navbar-nav flex-row align-items-center">
                    <li class="nav-item mx-2 px-2">
                        <a routerLink="/calendar">
                            <div class="icon-bell"></div>
                        </a>
                    </li>
                    <li class="nav-item mx-2 px-1">
                        <a routerLink="/messages">
                            <div class="icon-email"></div>
                        </a>
                    </li>
                    <li class="nav-item ms-4 me-2">
                        <a href="#" class="d-flex d-flex align-items-center jusitfy-content-end text-decoration-none" id="profileDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <avatar [user]="user" size="tiny"></avatar>
                        </a>

                        <ul class="dropdown-menu dropdown-menu-end text-small shadow position-absolute" aria-labelledby="profileDropdownMenu">
                            <li><a class="dropdown-item" routerLink="/profile/edit">{{ 'Профил' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        <ng-container *ngIf="user && (user | roleAny:'partner')">
                            <li><a class="dropdown-item" routerLink="/profile/company">{{ 'Фирмени данни' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        </ng-container>
                            <li><a class="dropdown-item" routerLink="/auth/logout">{{ 'Изход' | transloco }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="collapse navbar-collapse justify-content-end" >
                <ul class="navbar-nav mb-2 mb-md-0">
                    <!-- <a href="https://t.me/+86ohSNPnHEY0MDEy" class="text-nowrap text-white contact-number d-flex align-items-center" target="_blank">Telegram Group</a> -->
                    <li class="nav-item mx-3">
                        <app-language-switcher mode="dropdown" class="lang-switcher me-4"></app-language-switcher>
                    </li>
                    <!-- <li class="nav-item date mx-3">
                        {{ now | dateLocale:'EEEE, d MMMM YYYY' }}
                    </li> -->
                    <li class="nav-item mx-2">
                        <a routerLink="/calendar">
                            <div class="icon-bell"></div>
                        </a>
                    </li>
                    <li class="nav-item mx-2">
                        <a routerLink="/messages">
                            <div class="icon-email"></div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="d-flex d-flex align-items-center jusitfy-content-end text-decoration-none dropdown-toggle" id="profileDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <div class="d-flex align-items-start ms-2">
                                <div class="mx-2 text-end text user-name">
                                    {{ user | userName }}
                                    <small class="text-warning d-block user-role-title">{{ user?.role?.title }}</small>
                                </div>
                                <avatar [user]="user"></avatar>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end text-small shadow" aria-labelledby="profileDropdownMenu">
                            <li><a class="dropdown-item" routerLink="/profile/edit">{{ 'Профил' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        <ng-container *ngIf="user && (user | roleAny:'partner')">
                            <li><a class="dropdown-item" routerLink="/profile/company">{{ 'Фирмени данни' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        </ng-container>
                            <li><a class="dropdown-item" routerLink="/auth/logout">{{ 'Изход' | transloco }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
      </nav>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offCanvasSidebar">
        <div class="offcanvas-header pb-1">
            <h5 class="offcanvas-title" id="offCanvasSidebar">
                <img src="/assets/img/logos/logo-reverse.svg" alt="">
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="nav nav-pills nav-flush flex-column mb-auto border-top">
                <li class="nav-item mt-1" *ngFor="let menu of navigation">
                    <a href="#"
                        class="nav-link py-3 px-2"
                        aria-current="page"
                        [title]="menu.title"
                        [routerLink]="menu.url"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="menu.options || {exact: false}"
                        data-bs-dismiss="offcanvas" aria-label="Close"
                    >
                        <i [class]="menu.icon"></i>
                        {{menu.title}}
                    </a>
                </li>

                <li class="nav-item mt-1">
                    <div class=" border-top pt-2"></div>
                </li>

                <li class="nav-item mt-1">
                    <a href="https://t.me/+86ohSNPnHEY0MDEy"
                        class="nav-link  py-3 px-2 side-social-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        target="_blank"
                    >
                        <i class="icon-phone"></i>
                        {{ 'Телефон' | transloco }}
                    </a>
                </li>
                <li class="nav-item mt-1">
                    <a href="https://www.facebook.com/let.shakehands/"
                        class="nav-link  py-3 px-2 side-social-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        target="_blank"
                    >
                        <i class="fa-fw fa-brands fa-facebook-f"></i>
                        {{ 'Facebook' | transloco }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</header>


