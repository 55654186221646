import * as moment from 'moment';

import { GroupPaymentType } from "./group-payment-type.model";

export class GroupInstallment {
    type?: GroupPaymentType;
    price?: number;
    price_online?: number;
    date?: string;
    description?: string;

    parsed_date?: Date;

    static fromJson(fields: any): GroupInstallment {
        let item = Object.assign(new this(), fields);

        const parsedDate = moment.utc(fields?.date, true);
        item.parsed_date = parsedDate?.isValid() ? parsedDate?.toDate() : null;

        return item;
    }
}
