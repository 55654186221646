export class GroupModulePresentation {
    // https://learn.microsoft.com/en-us/microsoft-365/cloud-storage-partner-program/faq/file-sizes
    protected readonly MAX_PPTX_PREVIEW_SIZE: number = 300 * 1024 * 1024; //  300 MB;

    filename?: string;
    url?: string;
    image?: string;
    size?: number;

    static fromJson(fields: any): GroupModulePresentation {
        let item = Object.assign(new this(), fields);
        return item;
    }

    presentationUrl(): string|null {
        if (/\.(ppt|pptx)$/i.test(this.url ?? '') && (this.size ?? 0) < this.MAX_PPTX_PREVIEW_SIZE) {
            return 'https://view.officeapps.live.com/op/embed.aspx?src=' + this.url;
        }

        if (/\.(pdf)$/i.test(this.url ?? '')) {
            return 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + this.url;
        }

        return null;
    }
}
