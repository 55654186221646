<div *ngIf="mode === 'view' || disabled"
    (dblclick)="onEdit($event)"

    [class.d-inline-block]="!value?.length"
    [class.w-100]="value?.length"
>
    <div class="d-flex justify-content-start align-items-start">
        <a *ngIf="!disabled" href="#" (click)="onEdit($event)" class="edit order-1" [title]="'Редактиране' | transloco">
            <i class="fa-regular fa-pen-to-square edit-icon fa-fw ms-3"></i>
        </a>

        {{ value }}

        <i *ngIf="(!value || !value?.trim()?.length) && !disabled">{{ emptyLabel }}</i>
    </div>
</div>

<div *ngIf="mode === 'edit' && !disabled">
    <textarea class="form-control" [(ngModel)]="value" [rows]="rows" style="min-height: 0;" [placeholder]="placeholder" (click)="onClickComponent($event)"></textarea>

    <div class="input-group flex-nowrap align-items-center justify-content-center my-2">
        <button class="btn btn-sm btn-outline-success text-nowrap" type="button" (click)="onSave($event)">
            <i class="fa-solid fa-check fa-fw"></i> <span class="d-none d-sm-inline-block">{{ 'Запиши' | transloco }}</span>
        </button>
        <button class="btn btn-sm  btn-outline-danger text-nowrap" type="button" (click)="onCancel($event)">
            <i class="fa-solid fa-xmark fa-fw"></i> <span class="d-none d-sm-inline-block">{{ 'Отказ' | transloco }}</span>
        </button>
    </div>
</div>
