import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
    @Input() @HostBinding('class.wrapped') parentWrapped: boolean = false;
    @Input() @HostBinding('class.loading') loading: boolean = false;
    @Input() @HostBinding('class.has-data') hasData: boolean = false;

    constructor() { }

    ngOnInit(): void {

    }
}
